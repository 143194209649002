import { forwardRef, useEffect, useId, useState } from 'react';
import Currency from './Currency';

type InputSurchargeProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  className?: string;
  isError?: boolean;
  errMsg?: any;
  register?: any;
  defaultValue?: any;
  required?: boolean | undefined;
  rmbValue: number;
};

const InputSurcharge = forwardRef<HTMLInputElement, InputSurchargeProps>(({ rmbValue, required, register, defaultValue, errMsg, isError, label, className, ...restProps }, ref) => {
  const inputId = useId();

  const [value, setValue] = useState('');

  useEffect(() => {
    const formattedPrice = Intl.NumberFormat('vi-VN').format(defaultValue);
    setValue(formattedPrice);
  }, [defaultValue]);

  const handleChange = (e: any) => {
    let inputValue: any = e.target.value.replace(/\./g, '');

    if (inputValue.startsWith('0') && inputValue.length > 1) {
      inputValue = inputValue.slice(1);
    }

    if (/^[0-9]*$/.test(inputValue)) {
      if (inputValue.length > 0) {
        const formattedPrice = Intl.NumberFormat('vi-VN').format(inputValue);
        setValue(formattedPrice);
      } else {
        setValue(inputValue);
      }

      e.target.value = inputValue;
      restProps.onChange?.(e);
    }
  };

  return (
    <>
      <div className={'input-group input-group-merge ' + (className ? className : '')}>
        <div className="form-floating form-floating-outline">
          <input type="text" {...restProps} ref={ref} className={'form-control' + (isError ? ' is-invalid' : '')} id={inputId} onChange={handleChange} value={value} {...(register && register())} />
          <label htmlFor={inputId}>
            {label}
            {required && <span className="error-feedback"> *</span>}
          </label>
        </div>
        <span className={'input-group-text cursor-pointer color-invalid' + (restProps.disabled ? ' disabled' : '')}>
          <Currency currency="RMB" price={rmbValue ? rmbValue : 0} />
        </span>
      </div>
      {isError && <div className="error-feedback"> {errMsg} </div>}
    </>
  );
});
export default InputSurcharge;
