import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrderService } from '../../core/services/order.service';
import moment from 'moment';
import { CommonConstant } from '../../core/constants/common.constant';
import { useAppDispatch, useAppSelector } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import AppModel from '../../components/ui/AppModel';
import SuccForm from './components/SuccForm';
import { toast } from 'react-toastify';
import ViewOrderStatus from '../../components/common/ViewOrderStatus';
import ViewShipmentStatus from '../../components/common/ViewShipmentStatus';
import NumberFormat from '../../components/common/NumberFormat';
import Currency from '../../components/common/Currency';
import ShipmentDetail from './components/ShipmentDetail';
import DeliveryForm from './components/DeliveryForm';
import { userInfoSelector } from '../../libs/selectors/userSelector';
import { RoleConstant } from '../../core/constants/role.constant';
import PinCodeAuthentication from '../../components/common/PinCodeAuthentication';
import QrCodeModal from './components/QrCodeModal';
import Swal from 'sweetalert2';
import SliderImage from '../../components/common/SliderImage';

export default function OrderDetail() {
  const url = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userInfoSelector);
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [isOpenSuccFormModal, setIsOpenSuccFormModal] = useState<boolean>(false);
  const [isOpenShipmentDetailModal, setIsOpenShipmentDetailModal] = useState<boolean>(false);
  const [isOpenDeliveryFormModal, setIsOpenDeliveryFormModal] = useState<boolean>(false);
  const [selectedShipment, setSelectedShipment] = useState<any>({});
  const [totalNumberParcelExported, setTotalNumberParcelExported] = useState<number>(0);
  const [timelines, setTimeLines] = useState<any[]>([]);
  const [shipmentsDelivering, setShipmentsDelivering] = useState<any[]>([]);
  const [showBtnDelivery, setShowBtnDelivery] = useState<boolean>(false);
  const [isShowPhoneNumber, setIsShowPhoneNumber] = useState<boolean>(false);
  const [isOpenVerifyPinCodeModal, setIsOpenVerifyPinCodeModal] = useState<boolean>(false);
  const [isOpenQrCodeModal, setIsOpenQrCodeModal] = useState<boolean>(false);
  const [isOrderExists, setIsOrderExists] = useState<boolean>(true);
  const [atchFleSeqs, setAtchFleSeqs] = useState<any[]>([]);
  const [isShowSliderImage, setIsShowSliderImage] = useState<boolean>(false);

  useEffect(() => {
    getOrder();
  }, [id]);

  const getOrder = () => {
    dispatch(showSpinner());
    OrderService.getOrderById(id)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          setOrderDetail(res.data.responseData);
          convertData(res.data.responseData);
        } else {
          setIsOrderExists(false);
        }
      });
  };

  const convertData = (data: any) => {
    let shipmentTrackingTemps: any[] = [];
    let totalNumberParcelTemp = 0;
    let totalShipmentCompletedTemp = 0;
    let isShowBtnDelivey = false;
    data.shipments?.forEach((shipment: any, idx: any) => {
      let trackingTemps = shipment.trackings?.map((ele: any) => {
        ele.shipmentInfo = shipment;
        return ele;
      });
      if (shipment.status == CommonConstant.SHIPMENT_STATUS_SUCC) {
        totalShipmentCompletedTemp++;
      }
      shipmentTrackingTemps = [...shipmentTrackingTemps, ...trackingTemps];
      totalNumberParcelTemp += shipment.numberParcel;
      if (shipment.status == CommonConstant.SHIPMENT_STATUS_CC) {
        isShowBtnDelivey = true;
      }
    });

    setShowBtnDelivery(isShowBtnDelivey);
    shipmentTrackingTemps.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());

    setTimeLines(shipmentTrackingTemps);
    setTotalNumberParcelExported(totalNumberParcelTemp);
  };

  const onNavigateExport = () => {
    navigate(`/order-mgt/export/${id}`);
  };

  const onOpenSuccFormModal = (shipment: any) => {
    setIsOpenSuccFormModal(true);
    setSelectedShipment(shipment);
  };

  const onOpenShipmentDetailModal = (shipment: any) => {
    setIsOpenShipmentDetailModal(true);
    setSelectedShipment(shipment);
  };

  const onOpenDeliveryFormModal = (shipment?: any) => {
    const shipmentTemps = orderDetail.shipments?.filter((item: any) => item.status == CommonConstant.SHIPMENT_STATUS_CC);
    setShipmentsDelivering(shipmentTemps);
    setIsOpenDeliveryFormModal(true);
    setSelectedShipment(shipment);
  };

  const onCloseDeliveryFormModal = (event: any) => {
    setIsOpenDeliveryFormModal(false);
    if (event) {
      getOrder();
    }
  };

  const onSubmitSucc = () => {
    setIsOpenSuccFormModal(false);
    getOrder();
  };

  const countShipment = (status: any) => {
    let countExport = 0;
    let countCC = 0;
    let countSucc = 0;
    orderDetail.shipments?.forEach((element: any) => {
      if (element.status == CommonConstant.SHIPMENT_STATUS_EXPORT) countExport++;
      if (element.status == CommonConstant.SHIPMENT_STATUS_CC) countCC++;
      if (element.status == CommonConstant.SHIPMENT_STATUS_SUCC) countSucc++;
    });

    if (status == CommonConstant.SHIPMENT_STATUS_CC) {
      return orderDetail.shipments.length - countExport;
    }
    if (status == CommonConstant.SHIPMENT_STATUS_SUCC) {
      return orderDetail.shipments.length - countExport - countCC;
    }
    return 0;
  };

  const onEdit = () => {
    // if (orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT) {
    navigate(`/order-mgt/edit-order/${orderDetail.orderId}`);
    // }
  };

  const openModal = (item: any) => {
    if (currentUser.roleIds?.includes(RoleConstant.ROLE_WHVN) || currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN)) {
      if (item.status == CommonConstant.SHIPMENT_STATUS_EXPORT) {
        onOpenShipmentDetailModal(item);
      }
      if (item.status == CommonConstant.SHIPMENT_STATUS_CC) {
        // onOpenDeliveryFormModal(item);
        onOpenShipmentDetailModal(item);
      }

      if (item.status == CommonConstant.SHIPMENT_STATUS_SUCC) {
        onOpenShipmentDetailModal(item);
      }

      if (item.status == CommonConstant.SHIPMENT_STATUS_DELIVERING) {
        onOpenSuccFormModal(item);
      }
    } else {
      onOpenShipmentDetailModal(item);
    }
  };

  const onEditShipment = (event: any, shipment: any) => {
    event.stopPropagation();
    navigate(`/order-mgt/export/${orderDetail.orderId}?id=${shipment.id}`);
  };

  const onOpenVerifyPinCodeModal = () => {
    setIsOpenVerifyPinCodeModal(true);
  };

  const onViewPhoneNumber = (event: any) => {
    setIsOpenVerifyPinCodeModal(false);
    if (event) {
      setIsShowPhoneNumber(true);
      setTimeout(() => {
        setIsShowPhoneNumber(false);
      }, 90000);
    }
  };

  const onOpenQrCodeModal = () => {
    setIsOpenQrCodeModal(true);
  };

  const onDelete = () => {
    Swal.fire({
      title: 'Xác nhận xóa đơn hàng?',
      text: 'Bạn không thể hoàn tác lại được!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Xác nhận!',
      cancelButtonText: 'Hủy'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showSpinner());
        OrderService.deleteByOrderId(id)
          .finally(() => {
            setTimeout(() => {
              dispatch(hideSpinner());
            }, 500);
          })
          .then((res) => {
            if (res.data.status) {
              navigate('/order-mgt');
              return;
            }
            toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại.');
          });
      }
    });
  };

  const onViewImage = (datas: any) => {
    const temps = datas.map((item: any) => item.atchFleSeq);
    setAtchFleSeqs(temps);
    setIsShowSliderImage(true);
  };

  const onBack = () => {
    navigate('/order-mgt', {state: location.state});
  }

  return (
    <>
      {isOrderExists && (
        <>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 gap-6">
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center mb-1">
                <h5 className="mb-0">
                  Đơn hàng #<span className="text-primary">{orderDetail.orderId}</span>
                </h5>
                <ViewOrderStatus orderDetail={orderDetail}></ViewOrderStatus>
                {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
                  <div className="dropdown">
                    <button className="btn rounded-pill border-0 p-1 waves-effect waves-light show" type="button" id="popularInstructors" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="ri-settings-4-fill ri-20px"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="popularInstructors" data-popper-placement="top-end">
                      {/* {orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT && ( */}
                      <a className="dropdown-item waves-effect cursor-pointer" onClick={onEdit}>
                        Sửa đổi
                      </a>
                      {/* )} */}
                      {currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) && (
                        <a className="dropdown-item waves-effect cursor-pointer" onClick={onDelete}>
                          Xóa
                        </a>
                      )}
                    </div>
                  </div>
                )}

                <button className="btn rounded-pill border-0 p-1 waves-effect waves-light show" type="button" onClick={onOpenQrCodeModal}>
                  <i className="ri-qr-code-line ri-20px"></i>
                </button>
                {orderDetail.warningYn && (
                  <span className="ms-2  text-warning fst-italic">
                    <span>** Cảnh báo: {orderDetail.warningMsg}!</span>
                  </span>
                )}
              </div>
              <p className="mb-0 fst-italic">{moment(orderDetail.whEntryDate).format('YYYY/MM/DD HH:mm')}</p>
            </div>

            <div className="d-flex align-content-center flex-wrap gap-2">
              <button
                className="btn btn-cancel"
                onClick={onBack}
              >
                Quay lại
              </button>
              {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
                <>
                  {orderDetail.numberParcel > totalNumberParcelExported && (
                    <button className="btn btn-primary" onClick={onNavigateExport}>
                      Xuất kho
                    </button>
                  )}
                </>
              )}
              {showBtnDelivery && (
                <>
                  {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHVN)) && (
                    <button className="btn btn-order-delivering" onClick={onOpenDeliveryFormModal}>
                      Giao hàng
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-7">
              <div className="card mb-6">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title m-0">Thông tin chung</h5>
                </div>
                <div className="card-body ">
                  <div className="pb-3 d-flex justify-content-between field-info">
                    <div>Mã đơn hàng</div>
                    <div>
                      {orderDetail.orderId}{' '}
                      <a
                        onClick={() => {
                          navigator.clipboard.writeText(orderDetail.orderId);
                          toast.success('Đã copy!');
                        }}
                        className="cursor-pointer"
                      >
                        <i className="ri-file-copy-2-line"></i>
                      </a>
                    </div>
                  </div>
                  {/* {orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT && ( */}
                  <>
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Mã khách hàng</div>
                      <div>{orderDetail.customerId ? orderDetail.customerId : '--'}</div>
                    </div>
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Seller</div>
                      <div>{orderDetail.sellerId ? orderDetail.sellerId : '--'}</div>
                    </div>
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>SĐT nhận</div>
                      <div>
                        {(currentUser.username == orderDetail.sellerId || currentUser.username == orderDetail.customerId) && <>{orderDetail.recipientPhoneNumber ? orderDetail.recipientPhoneNumber : '--'}</>}
                        {currentUser.username != orderDetail.sellerId && currentUser.username != orderDetail.customerId && (
                          <>
                            {isShowPhoneNumber ? (
                              <>
                                {orderDetail.recipientPhoneNumber ? (
                                  <>
                                    {orderDetail.recipientPhoneNumber}{' '}
                                    <a
                                      onClick={() => {
                                        navigator.clipboard.writeText(orderDetail.recipientPhoneNumber);
                                        toast.success('Đã copy!');
                                      }}
                                      className="cursor-pointer"
                                    >
                                      <i className="ri-file-copy-2-line"></i>
                                    </a>
                                  </>
                                ) : (
                                  '--'
                                )}
                              </>
                            ) : (
                              <div className="d-flex align-items-center">
                                <div>**********&ensp;</div>
                                <a onClick={() => onOpenVerifyPinCodeModal()} className="cursor-pointer" style={{ paddingBottom: '7px' }}>
                                  <i className="ri-eye-line"></i>
                                </a>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                  {/* )} */}

                  {orderDetail.note && (
                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Note</div>
                      <div>{orderDetail.note}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card mb-6">
                <div className="card-header">
                  <h5 className="card-title m-0">Trạng thái đơn hàng</h5>
                </div>
                <div className="card-body mt-3">
                  <ul className="timeline pb-0 mb-0">
                    {orderDetail.trackings?.map((tracking: any, idx: any) => (
                      <React.Fragment key={idx}>
                        {idx == 0 && (
                          <li className={'timeline-item timeline-item-transparent ' + (timelines && timelines.length > 0 ? 'border-left-dashed' : 'border-transparent pb-0')}>
                            <span className="timeline-point timeline-point-primary"></span>
                            <div className="timeline-event">
                              <div className="timeline-header mb-2">
                                {tracking.status == CommonConstant.TRACKING_STATUS_IMPORT && <h6 className="mb-0">Đã nhập hàng (Mã đơn hàng: #{orderDetail.orderId})</h6>}
                                {tracking.status == CommonConstant.TRACKING_STATUS_EXPORT && <h6 className="mb-0">Đã xuất kho</h6>}
                                {tracking.status == CommonConstant.TRACKING_STATUS_CC && <h6 className="mb-0">Đã thông quan</h6>}
                                {tracking.status == CommonConstant.TRACKING_STATUS_SUCC && <h6 className="mb-0">Giao thành công</h6>}
                                <small className="text-muted">{moment(tracking.createdDate).format('YYYY/MM/DD HH:mm')}</small>
                              </div>
                              {tracking.status == CommonConstant.TRACKING_STATUS_EXPORT && <p className="mt-1 mb-2">Đã xuất {orderDetail.shipments?.length} xe</p>}
                              {tracking.status == CommonConstant.TRACKING_STATUS_CC && (
                                <p className="mt-1 mb-2">
                                  Đã thông quan {countShipment(CommonConstant.SHIPMENT_STATUS_CC)}/{orderDetail.shipments?.length} xe
                                </p>
                              )}
                              {tracking.status == CommonConstant.TRACKING_STATUS_SUCC && (
                                <p className="mt-1 mb-2">
                                  Đã giao hàng thành công {countShipment(CommonConstant.SHIPMENT_STATUS_SUCC)}/{orderDetail.shipments?.length} xe
                                </p>
                              )}
                              <div className="timeline-image">
                                <div className="d-flex">
                                  {tracking.trackingImages.map((image: any, idx: any) => (
                                    <React.Fragment key={idx}>
                                      <div className="avatar avatar-xl me-3" onClick={(event) => onViewImage(tracking.trackingImages)}>
                                        <img src={`${url}/public/imageView/${image.atchFleSeq}`} alt="Avatar" className="rounded-3" />
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                    {timelines?.map((tracking, idx) => (
                      <li key={idx} className={'timeline-item timeline-item-transparent ' + (timelines.length - 1 == idx && orderDetail.status != CommonConstant.ORDER_STATUS_SUCC ? 'border-transparent pb-0' : 'border-left-dashed')}>
                        <span className="timeline-point timeline-point-primary"></span>
                        <div className="timeline-event">
                          <div className="timeline-header mb-2">
                            <h6 className="mb-0">
                              {tracking.status == CommonConstant.TRACKING_STATUS_EXPORT && (
                                <>
                                  Đã xuất {tracking.shipmentInfo.numberParcel}/{orderDetail.numberParcel} kiện, xe {tracking.shipmentInfo.licensePlate} - {tracking.shipmentInfo.vehicleType} - {tracking.shipmentInfo.borderGate} -{' '}
                                  {tracking.shipmentInfo.address}
                                </>
                              )}
                              {tracking.status == CommonConstant.TRACKING_STATUS_CC && (
                                <>
                                  Đã thông quan {tracking.shipmentInfo.numberParcel}/{orderDetail.numberParcel} kiện, xe {tracking.shipmentInfo.licensePlate} - {tracking.shipmentInfo.vehicleType} - {tracking.shipmentInfo.borderGate} -{' '}
                                  {tracking.shipmentInfo.address}
                                </>
                              )}

                              {tracking.status == CommonConstant.TRACKING_STATUS_DELIVERING && (
                                <>
                                  Đang giao {tracking.shipmentInfo.numberParcel}/{orderDetail.numberParcel} kiện, xe {tracking.shipmentInfo.licensePlate} - {tracking.shipmentInfo.vehicleType} - {tracking.shipmentInfo.borderGate} -{' '}
                                  {tracking.shipmentInfo.address}
                                </>
                              )}

                              {tracking.status == CommonConstant.TRACKING_STATUS_SUCC && (
                                <>
                                  Đã giao thành công {tracking.shipmentInfo.numberParcel}/{orderDetail.numberParcel} kiện, xe {tracking.shipmentInfo.licensePlate} - {tracking.shipmentInfo.vehicleType} - {tracking.shipmentInfo.borderGate} -{' '}
                                  {tracking.shipmentInfo.address}
                                </>
                              )}
                            </h6>
                            <small className="text-muted">{moment(tracking.createdDate).format('YYYY/MM/DD HH:mm')}</small>
                          </div>
                          <div className="timeline-image">
                            <div className="d-flex">
                              {tracking.trackingImages?.map((image: any, idx: any) => (
                                <React.Fragment key={idx}>
                                  <div className="avatar avatar-xl me-3" onClick={(event) => onViewImage(tracking.trackingImages)}>
                                    <img src={`${url}/public/imageView/${image.atchFleSeq}`} alt="Avatar" className="rounded-3" />
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                    {orderDetail.status == CommonConstant.ORDER_STATUS_SUCC && (
                      <li className="timeline-item timeline-item-transparent border-transparent pb-0">
                        <span className="timeline-point timeline-point-primary"></span>
                        <div className="timeline-event pb-0">
                          <div className="timeline-header mb-2">
                            <h6 className="mb-0">Giao hàng thành công</h6>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="card mb-6">
                <div className="card-body">
                  <h5 className="card-title mb-6">Thông tin hàng hóa</h5>
                  <div>
                    <div className="pb-3 d-flex justify-content-between field-info">
                      <div>Số kiện</div>
                      <div>
                        <NumberFormat value={orderDetail.numberParcel}></NumberFormat>
                      </div>
                    </div>
                    {orderDetail.weight > 0 && (
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Số cân</div>
                        <div>
                          {orderDetail.weight ? (
                            <>
                              <NumberFormat value={orderDetail.weight}></NumberFormat> kg
                            </>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                    )}

                    {orderDetail.size > 0 && (
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Số khối</div>
                        <div>
                          {orderDetail.size ? (
                            <>
                              <NumberFormat value={orderDetail.size}></NumberFormat> m<sup>3</sup>
                            </>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                    )}

                    {orderDetail.piece > 0 && (
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Số cái</div>
                        <div>{orderDetail.piece ? <NumberFormat value={orderDetail.piece}></NumberFormat> : '--'}</div>
                      </div>
                    )}

                    <div className="py-3 d-flex justify-content-between field-info">
                      <div>Loại hàng hóa</div>
                      <div>{orderDetail.productType ? orderDetail.productType : '--'}</div>
                    </div>
                    <>
                      {orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT && (
                        <>
                          {(orderDetail.domesticFee > 0 || orderDetail.towingFee > 0 || orderDetail.forkliftFee > 0 || orderDetail.woodenFee > 0 || orderDetail.payOnHehalf > 0) && (
                            <div className="py-3">
                              <div>Cước phụ</div>
                              <div className="ps-10 mt-2">
                                <div className="row">
                                  {orderDetail.domesticFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Cước nội địa</div>
                                        <div>
                                          <Currency price={orderDetail.domesticFee} currency="RMB"></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.towingFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Kéo xe</div>
                                        <div>
                                          <Currency price={orderDetail.towingFee} currency="RMB"></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.forkliftFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Xe nâng</div>
                                        <div>
                                          <Currency price={orderDetail.forkliftFee} currency="RMB"></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.woodenFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Đóng gỗ</div>
                                        <div>
                                          <Currency price={orderDetail.woodenFee} currency="RMB"></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.payOnHehalf > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Thanh toán hộ</div>
                                        <div>
                                          <Currency price={orderDetail.payOnHehalf} currency="RMB"></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_SELLER) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
                            <div className="py-3 d-flex justify-content-between field-info border-top">
                              <div className="">Tổng tiền</div>
                              <div className="fw-semibold">
                                <Currency price={orderDetail.totalFee ? orderDetail.totalFee : 0} currency="RMB"></Currency>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT && (
                        <>
                          {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_SELLER) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && orderDetail.exportFee?.shippingFee > 0 && (
                            <div className="my-3 d-flex justify-content-between field-info">
                              <div>Cước vận chuyển</div>
                              <div className="fw-semibold">
                                <Currency price={orderDetail.exportFee?.shippingFee}></Currency>
                              </div>
                            </div>
                          )}
                          {(orderDetail.exportFee?.domesticFee > 0 || orderDetail.exportFee?.towingFee > 0 || orderDetail.exportFee?.forkliftFee > 0 || orderDetail.exportFee?.woodenFee > 0 || orderDetail.exportFee?.payOnHehalf > 0) && (
                            <div className="py-3">
                              <div>Cước phụ</div>
                              <div className="ps-10 mt-2">
                                <div className="row">
                                  {orderDetail.exportFee?.domesticFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Cước nội địa</div>
                                        <div>
                                          <Currency price={orderDetail.exportFee?.domesticFee}></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.exportFee?.towingFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Kéo xe</div>
                                        <div>
                                          <Currency price={orderDetail.exportFee?.towingFee}></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.exportFee?.forkliftFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Xe nâng</div>
                                        <div>
                                          <Currency price={orderDetail.exportFee?.forkliftFee}></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.exportFee?.woodenFee > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Đóng gỗ</div>
                                        <div>
                                          <Currency price={orderDetail.exportFee?.woodenFee}></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {orderDetail.exportFee?.payOnHehalf > 0 && (
                                    <div className="col-sm-12 col-lg-6">
                                      <div className="d-flex justify-content-between field-info">
                                        <div>Thanh toán hộ</div>
                                        <div>
                                          <Currency price={orderDetail.exportFee?.payOnHehalf}></Currency>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_SELLER) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
                            <div className="py-3 d-flex justify-content-between field-info border-top">
                              <div className="">Tổng tiền</div>
                              <div className="fw-semibold">
                                <Currency price={orderDetail.exportFee?.totalFee ? orderDetail.exportFee?.totalFee : 0}></Currency>
                              </div>
                            </div>
                          )}
                          <div className="d-flex justify-content-between field-info">
                            <div className="fs-5 fw-semibold">Số tiền cần thanh toán</div>
                            <div className="text-danger fw-semibold">
                              <Currency price={orderDetail.exportFee?.payAmount ? orderDetail.exportFee?.payAmount : 0}></Currency>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
              {orderDetail.shipments?.length > 0 && (
                <div className="card mb-6">
                  <div className="card-body">
                    <h5 className="card-title mb-3">Thông tin vận chuyển</h5>

                    <div className="accordion ps-0 pt-0">
                      {orderDetail.shipments?.map((item: any, idx: any) => (
                        <div key={idx} className="accordion-item shadow-none border-0 my-0 py-2" onClick={(event) => openModal(item)}>
                          <div className="accordion-header border-0">
                            <div className="pe-0 shadow-none collapsed">
                              <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                <div className="avatar-wrapper">
                                  <div className="avatar me-4">
                                    <span className="avatar-initial rounded-circle bg-label-secondary">
                                      <i className="ri-car-line ri-24px"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="fw-normal" style={{ width: '100%' }}>
                                  <div className="d-flex justify-content-between mb-1">
                                    <div className="d-flex align-items-center text-heading">
                                      <div className="fw-semibold">
                                        {item.licensePlate} - {item.vehicleType} - {item.borderGate} - {item.address}
                                      </div>
                                      {/* <div className="fw-semibold">{item.vehicleType}</div>
                                  <div className="fw-semibold">{item.borderGate}</div>
                                  <div className="fw-semibold">{item.address}</div> */}
                                      <div className="ms-2">
                                        <ViewShipmentStatus status={item.status}></ViewShipmentStatus>
                                      </div>
                                    </div>
                                    {/* {item.status == CommonConstant.SHIPMENT_STATUS_EXPORT && ( */}
                                    {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
                                      <div className="dropdown">
                                        <button
                                          onClick={(event) => event.stopPropagation()}
                                          className="btn btn-text-secondary rounded-pill text-muted border-0 p-1 waves-effect waves-light"
                                          type="button"
                                          id="organicSessionsDropdown"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="ri-more-2-line ri-20px"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="organicSessionsDropdown">
                                          {/* <a className="dropdown-item waves-effect" onClick={(event) => openModal(item)}>Thông tin chi tiết</a> */}
                                          <a className="dropdown-item waves-effect" onClick={(event) => onEditShipment(event, item)}>
                                            Sửa đổi
                                          </a>
                                          {/* <a className="dropdown-item waves-effect" href="javascript:void(0);">Last Year</a> */}
                                        </div>
                                      </div>
                                    )}

                                    {/* )} */}
                                  </div>
                                  <div>
                                    <div className="py-1 d-inline-block justify-content-between field-info">
                                      <span>Số kiện</span>
                                      <span className="fw-semibold">{item.numberParcel ? <NumberFormat value={item.numberParcel}></NumberFormat> : '--'}</span>
                                    </div>
                                    <div className="py-1 d-inline-block justify-content-between field-info">
                                      <span>Số cân</span>
                                      <span className="fw-semibold">
                                        {item.weight ? (
                                          <>
                                            <NumberFormat value={item.weight}></NumberFormat> kg
                                          </>
                                        ) : (
                                          '--'
                                        )}
                                      </span>
                                    </div>
                                    <div className="py-1 d-inline-block justify-content-between field-info">
                                      <span>Số khối</span>
                                      <span className="fw-semibold">
                                        {item.size ? (
                                          <>
                                            <NumberFormat value={item.size}></NumberFormat> m<sup>3</sup>
                                          </>
                                        ) : (
                                          '--'
                                        )}
                                      </span>
                                    </div>
                                    <div className="py-1 d-inline-block justify-content-between field-info">
                                      <span>Số cái</span>
                                      <span className="fw-semibold">{item.piece ? <NumberFormat value={item.piece}></NumberFormat> : '--'}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <AppModel isShow={isOpenSuccFormModal} onClose={() => setIsOpenSuccFormModal(false)} title="Thông tin giao hàng" size="lg">
            <SuccForm orderDetail={orderDetail} shipment={selectedShipment} onCancel={() => setIsOpenSuccFormModal(false)} onSubmit={onSubmitSucc}></SuccForm>
          </AppModel>

          <AppModel isShow={isOpenDeliveryFormModal} onClose={onCloseDeliveryFormModal} title="Thông tin giao hàng" size="lg">
            <DeliveryForm orderDetail={orderDetail} shipments={shipmentsDelivering} onCancel={() => setIsOpenDeliveryFormModal(false)} onSubmit={(event) => onCloseDeliveryFormModal(event)}></DeliveryForm>
          </AppModel>

          <AppModel isShow={isOpenShipmentDetailModal} onClose={() => setIsOpenShipmentDetailModal(false)} title="Thông tin giao hàng" size="lg">
            <ShipmentDetail orderDetail={orderDetail} shipment={selectedShipment}></ShipmentDetail>
          </AppModel>

          <AppModel isShow={isOpenVerifyPinCodeModal} onClose={() => setIsOpenVerifyPinCodeModal(false)} title="Xác thực mã pin" size="sm">
            <PinCodeAuthentication onClose={(event) => onViewPhoneNumber(event)}></PinCodeAuthentication>
          </AppModel>

          <AppModel isShow={isOpenQrCodeModal} onClose={() => setIsOpenQrCodeModal(false)} title="QR CODE" size="sm">
            <QrCodeModal value={id}></QrCodeModal>
          </AppModel>
        </>
      )}

      {!isOrderExists && (
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="mb-1">Đơn hàng không tồn tại</h4>
          </div>
          <div className="d-flex align-content-center flex-wrap gap-4">
            <button type="button" className="btn btn-outline-secondary" onClick={() => navigate(`/order-mgt`)}>
              Quay lại
            </button>
          </div>
        </div>
      )}
      {isShowSliderImage && <SliderImage onClose={() => setIsShowSliderImage(false)} atchFleSeqs={atchFleSeqs}></SliderImage>}
    </>
  );
}
