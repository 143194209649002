'use client';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
// import createWebStorage from 'redux-persist/es/storage/createWebStorage';
import layoutSlice from './features/layoutSlice';
import userInfoSlice from './features/userInfoSlice';
import spinnerSlice from './features/spinnerSlice';
import storage from 'redux-persist/lib/storage';

// const createNoopStorage = () => {
//     return {
//       getItem(_key: any) {
//         return Promise.resolve(null);
//       },
//       setItem(_key: any, value: any) {
//         return Promise.resolve(value);
//       },
//       removeItem(_key: any) {
//         return Promise.resolve();
//       },
//     };
//   };
  
// const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['layoutReducer', 'spinnerReducer']
};

const rootReducer = combineReducers({
    layoutReducer: layoutSlice,
    userReducer: userInfoSlice,
    spinnerReducer: spinnerSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const makeStore = () => {
    return configureStore({
        reducer: persistedReducer,
        // middleware: () => new Tuple(serializableMiddleware),
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({ serializableCheck: false });
        }
    });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];