import { RoleConstant } from "../core/constants/role.constant";

export type Menu = {
    menuId: any;
    menuName: string;
    url: string;
    icon: string;
    orderNo: number;
    roles: any[];
}

export const MENUS: Menu[] = [
    {
        menuId: 1,
        menuName: "Thống kê",
        url: '/dashboard',
        icon: 'ri-home-smile-line',
        roles: [RoleConstant.ROLE_ADMIN],
        orderNo: 1
    },
    {
        menuId: 2,
        menuName: "Quản lý đơn hàng",
        url: '/order-mgt',
        icon: 'ri-list-unordered',
        roles: [RoleConstant.ROLE_ADMIN, RoleConstant.ROLE_CUSTOMER, RoleConstant.ROLE_SELLER, RoleConstant.ROLE_WHTQ, RoleConstant.ROLE_WHVN],
        orderNo: 2
    },
    {
        menuId: 3,
        menuName: "Quản lý hàng tồn kho",
        url: '/inv-mgt',
        icon: 'ri-grid-line',
        roles: [RoleConstant.ROLE_ADMIN, RoleConstant.ROLE_WHTQ, RoleConstant.ROLE_WHVN],
        orderNo: 3
    },
    {
        menuId: 4,
        menuName: "Quản lý xe",
        url: '/vehicles',
        icon: 'ri-car-line',
        roles: [RoleConstant.ROLE_ADMIN, RoleConstant.ROLE_WHTQ, RoleConstant.ROLE_WHVN],
        orderNo: 4
    },
    {
        menuId: 5,
        menuName: "Tạo ảnh mã đơn",
        url: '/export-pdf',
        icon: 'ri-file-pdf-line',
        roles: [RoleConstant.ROLE_ADMIN, RoleConstant.ROLE_WHTQ, RoleConstant.ROLE_WHVN],
        orderNo: 5
    },
    {
        menuId: 6,
        menuName: "Quản lý người dùng",
        url: '/user-mgt',
        icon: 'ri-user-line',
        roles: [RoleConstant.ROLE_ADMIN],
        orderNo: 6
    },
    {
        menuId: 7,
        menuName: "Cài đặt",
        url: '/settings',
        icon: 'ri-settings-4-line',
        roles: [RoleConstant.ROLE_ADMIN, RoleConstant.ROLE_CUSTOMER, RoleConstant.ROLE_SELLER, RoleConstant.ROLE_WHTQ, RoleConstant.ROLE_WHVN],
        orderNo: 7
    },
]