import { useState } from 'react';
import UploadFile from '../../../components/ui/UploadFile';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';
import { ImageInspectionService } from '../../../core/services/image-inspection.service';

type UploadFileModalProps = {
  onSubmit?: (event: any) => void;
  onClose?: (event?: any) => void;
  licensePlate: any;
};

export default function UploadFileModal(props: UploadFileModalProps) {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<any[]>([]);

  const onChange = (event: any) => {
    setImages(event.filesToAdd);
  };

  const onSubmitForm = () => {
    if (!images || images.length <= 0 ) {
      toast.error('Chưa có hình ảnh kiểm hàng');
      return;
    }
    dispatch(showSpinner());
    ImageInspectionService.uploadImageInspection(props.licensePlate, images)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
          return;
        }
        props.onClose?.(true);
      });
  };

  return (
    <>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 py-3">
            {/* <div className="text-heading mb-2">Hình ảnh kiểm hàng</div> */}
            <UploadFile onChange={onChange} accept="image/*" multiple></UploadFile>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => props.onClose?.(false)}>
          Hủy
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onSubmitForm}>
          Lưu
        </button>
      </div>
    </>
  );
}
