import React from "react";

interface IProps {
  value: number;
}

const NumberFormat: React.FC<IProps> = ({ value }) => {
  const formatted = Intl.NumberFormat('vi-VN').format(value);
  return (
    <>
    {/* {Intl.NumberFormat('vi-VN', {
      style: 'currency', 
      currency: 'VND' // Change this
      }).format(price)} */}
      {formatted}
    </>
  );
}

export default NumberFormat;