import { useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../core/services/common/authentication.service';
import { useAppDispatch, useAppSelector } from '../libs/hook';
import { userInfoSelector } from '../libs/selectors/userSelector';
import { useEffect, useState } from 'react';
import { PaginationConfig } from '../core/config/PaginationConfig';
import { NotificationService } from '../core/services/notification.service';
import { hideSpinner, showSpinner } from '../libs/features/spinnerSlice';
import Cookies from 'universal-cookie';
import { AuthConstant } from '../core/constants/auth.constant';
import moment from 'moment';

export default function AppHeader() {
  const viewImgUrl = process.env.REACT_APP_API_VIEW_IMAGE;

  const navigate = useNavigate();
  const currentUser = useAppSelector(userInfoSelector);
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const [grantedNotificationsPermission, setGrantedNotificationsPermission] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [searchRequest, setSearchRequest] = useState<any>({});
  const [eventSource, setEventSource] = useState<any>(null);
  const [newNoti, setNewNoti] = useState<any>();
  const [totalNotiUnread, setTotalNotiUnread] = useState<any>(0);
  const [isShowSearchScreen, setIsShowSearchScreen] = useState<boolean>(false);

  // useEffect(() => {
  //   let retryTimeout: any;
  //   const connectToSSE = () => {
  //     checkNotificationPermission();
  //     const audio = new Audio('../assets/sound/facebook-notify.mp3');
  //     const token = cookies.get(AuthConstant.ACCESS_TOKEN);
  //     const source = new EventSource(`${process.env.REACT_APP_API_URL}/notification/get-new?token=${token}`);

  //     source.onopen = (e) => {
  //       console.log('SSE 3 Connected !');
  //     };

  //     source.onmessage = (event) => {
  //       const data = JSON.parse(event.data);
  //       setNewNoti(data);
  //     };

  //     source.onerror = (error) => {
  //       console.log('SSE connection error, retrying in 5 seconds...');
  //       source.close();
  //       retryTimeout = setTimeout(() => {
  //         connectToSSE();
  //       }, 5000);
  //     };

  //     setEventSource(source);
  //   };

  //   connectToSSE();

  //   return () => {
  //     if (eventSource) {
  //       eventSource.close();
  //     }
  //     if (retryTimeout) {
  //       clearTimeout(retryTimeout);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    init();
    countNotiUnread();
  }, []);

  useEffect(() => {
    if (newNoti) {
      let temps = notifications;
      temps.push(newNoti);
      setNotifications(temps);
    }
  }, [newNoti]);

  const init = () => {
    searchRequest.page = 0;
    searchRequest.size = PaginationConfig.PAGE_SIZE;
    setSearchRequest({ ...searchRequest });
    getNotifications(searchRequest);
  };

  const getNotifications = (params: any) => {
    dispatch(showSpinner());
    NotificationService.getNotifications(params)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setNotifications(res.data);
      });
  };

  const onLogout = () => {
    AuthenticationService.logout();
  };

  const checkNotificationPermission = () => {
    if (!window.Notification) {
      console.log('Browser does not support notifications.');
    } else {
      if (Notification.permission === 'granted') {
        setGrantedNotificationsPermission(true);
      } else {
        Notification.requestPermission()
          .then(function (p) {
            if (p === 'granted') {
              setGrantedNotificationsPermission(true);
            } else {
              setGrantedNotificationsPermission(false);
            }
          })
          .catch(function (err) {
            // this.grantedNotificationsPermission = false;
          });
      }
    }
  };

  const onNavigate = (item: any) => {
    markAsRead(item.id);
    navigate(`/order-mgt/detail/${item.orderId}`);
  };

  const countNotiUnread = () => {
    NotificationService.countNotiUnread().then((res) => {
      if (res.data.status) {
        setTotalNotiUnread(res.data.responseData);
      }
    });
  };

  const markAsRead = (id: any) => {
    NotificationService.markAsReadById(id).then((res) => {
      init();
    });
  };

  const onDeleteNoti = (event: any, id: any) => {
    event.stopPropagation();
    NotificationService.deleteById(id).then((res) => {
      init();
    });
  };

  return (
    <>
      <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0   d-xl-none ">
          <a className="nav-item nav-link px-0 me-xl-6">
            <i className="ri-menu-fill ri-22px"></i>
          </a>
        </div>

        <div className={"navbar-nav-right d-flex align-items-center" + (isShowSearchScreen ? ' d-none' : '') } id="navbar-collapse">
          <div className="navbar-nav align-items-center">
            <div className="nav-item navbar-search-wrapper mb-0">
              <a className="nav-item nav-link search-toggler fw-normal px-0" onClick={() => setIsShowSearchScreen(true)}>
                <i className="ri-search-line ri-22px scaleX-n1-rtl me-3"></i>
                <span className="d-none d-md-inline-block text-muted">Tìm kiếm (Ctrl+/)</span>
              </a>
            </div>
          </div>

          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-4 me-xl-1">
              <a className="nav-link btn btn-text-secondary rounded-pill btn-icon dropdown-toggle hide-arrow" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <i className="ri-notification-2-line ri-22px"></i>
                {totalNotiUnread > 0 && <span className="position-absolute top-0 start-50 translate-middle-y badge badge-dot bg-danger mt-2 border"></span>}
              </a>
              <ul className="dropdown-menu dropdown-menu-end py-0">
                <li className="dropdown-menu-header border-bottom py-50">
                  <div className="dropdown-header d-flex align-items-center py-4">
                    <h6 className="mb-0 me-auto">Thông báo</h6>
                    <div className="d-flex align-items-center">
                      {totalNotiUnread > 0 && <span className="badge rounded-pill bg-label-primary fs-xsmall me-2">{totalNotiUnread} thông báo mới</span>}

                      {/* <a className="btn btn-text-secondary rounded-pill btn-icon dropdown-notifications-all" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark all as read">
                        <i className="ri-mail-open-line text-heading ri-20px"></i>
                      </a> */}
                    </div>
                  </div>
                </li>
                <li className="dropdown-notifications-list scrollable-container " style={{ overflowY: 'auto' }}>
                  <ul className="list-group list-group-flush">
                    {notifications.map((item, idx) => (
                      <li key={idx} className="list-group-item list-group-item-action dropdown-notifications-item" onClick={() => onNavigate(item)}>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar">
                              <span className="avatar-initial rounded-circle bg-label-success">
                                <i className="ri-truck-line"></i>
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mb-1 small">{item.title}</h6>
                            <small className="mb-1 d-block text-body">{item.content}</small>
                            <small className="text-muted">{moment(item.createdDate).format('DD-MM-yyyy hh:mm')}</small>
                          </div>
                          <div className="flex-shrink-0 dropdown-notifications-actions">
                            {!item.readYn && (
                              <a className="dropdown-notifications-read">
                                <span className="badge badge-dot"></span>
                              </a>
                            )}
                            <a className="dropdown-notifications-archive" onClick={(event) => onDeleteNoti(event, item.id)}>
                              <span className="ri-close-line ri-20px"></span>
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="border-top">
                  <div className="d-grid p-4">
                    <a
                      className="btn btn-primary btn-sm d-flex"
                      onClick={() => {
                        navigate('/settings?tab=3');
                      }}
                    >
                      <small className="align-middle text-white">Xem tất cả</small>
                    </a>
                  </div>
                </li>
              </ul>
            </li>

            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <a className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                <div className="avatar avatar-online">
                  <img src={`${viewImgUrl}/${currentUser.imgUrl}`} className="rounded-circle" />
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a className="dropdown-item" href="pages-account-settings-account.html">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-2">
                        <div className="avatar avatar-online">
                          <img src="../../assets/img/avatars/1.png" className="rounded-circle" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-medium d-block small">{currentUser.fullName}</span>
                        <small className="text-muted">{currentUser?.role?.roleName}</small>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => navigate('/settings')}>
                    <i className="ri-user-3-line ri-22px me-3"></i>
                    <span className="align-middle">Thông tin tài khoản</span>
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => navigate('/settings?tab=2')}>
                    <i className="ri-settings-4-line ri-22px me-3"></i>
                    <span className="align-middle">Cài đặt</span>
                  </a>
                </li>

                <li>
                  <div className="d-grid px-4 pt-2 pb-1">
                    <a className="btn btn-sm btn-danger d-flex" onClick={onLogout}>
                      <small className="align-middle">Đăng xuất</small>
                      <i className="ri-logout-box-r-line ms-2 ri-16px"></i>
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className={"navbar-search-wrapper search-input-wrapper" + (isShowSearchScreen ? '' : ' d-none')}>
          <input type="text" className="form-control search-input container-xxl border-0" placeholder="Tìm kiếm..." aria-label="Tìm kiếm..." />
          <i className="ri-close-fill search-toggler cursor-pointer"></i>
        </div>
      </nav>
    </>
  );
}
