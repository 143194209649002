import axios from 'axios';
import Cookies from 'universal-cookie';
import { AuthConstant } from '../../constants/auth.constant';

export const AuthenticationService = {
  getCurrentUserInfo() {
    const url = process.env.REACT_APP_API_URL + '/user/get-info';
    return axios.get(url);
  },

  login(request: any) {
    const url = process.env.REACT_APP_API_URL + '/auth/login';
    return axios.post(url, request);
  },

  logout() {
    const cookies = new Cookies();
    cookies.remove(AuthConstant.ACCESS_TOKEN);
    window.location.href = '/login';
  }
};
