import { useEffect, useState } from 'react';
import { CommonConstant } from '../../core/constants/common.constant';

type ViewOrderStatusProps = {
  orderDetail: any;
};

export default function ViewOrderStatus({ orderDetail }: ViewOrderStatusProps) {
  const [totalExport, setTotalExport] = useState<number>(0);
  const [totalCc, setTotalCc] = useState<number>(0);
  const [totalDelivering, setTotalDelivering] = useState<number>(0);
  const [totalSucc, setTotalSucc] = useState<number>(0);
  const [allSuccess, setAllSuccess] = useState<number>(0);

  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    let totalExportTemp = 0;
    let totalCcTemp = 0;
    let totalDeliveringTemp = 0;
    let totalSuccTemp = 0;

    let allSuccessTemp = 0;
    orderDetail.shipments?.forEach((item: any) => {
      if (item.status == CommonConstant.SHIPMENT_STATUS_EXPORT) {
        totalExportTemp += item.numberParcel;
      }
      if (item.status == CommonConstant.SHIPMENT_STATUS_CC) {
        totalCcTemp += item.numberParcel;
      }
      if (item.status == CommonConstant.SHIPMENT_STATUS_DELIVERING) {
        totalDeliveringTemp += item.numberParcel;
      }
      if (item.status == CommonConstant.SHIPMENT_STATUS_SUCC) {
        totalSuccTemp += item.numberParcel;
        allSuccessTemp++;
      }
    });

    if (totalSuccTemp > 0) {
      setStatus(CommonConstant.ORDER_STATUS_SUCC);
    } else if (totalDeliveringTemp > 0) {
      setStatus(CommonConstant.ORDER_STATUS_DELIVERING);
    } else if (totalCcTemp > 0) {
      setStatus(CommonConstant.ORDER_STATUS_CC);
    } else if (totalExportTemp > 0) {
      setStatus(CommonConstant.ORDER_STATUS_EXPORT);
    } else {
      setStatus(CommonConstant.ORDER_STATUS_IMPORT);
    }

    setTotalExport(totalExportTemp);
    setTotalCc(totalCcTemp);
    setTotalDelivering(totalDeliveringTemp);
    setTotalSucc(totalSuccTemp);
    setAllSuccess(allSuccessTemp);

    // setTotalShimentExport(totalExportTemp);
    // setTotalShimentCc(totalCcTemp);
    // setTotalShimentSucc(totalSuccTemp);
  }, [orderDetail]);

  if (orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT) {
    return <span className="ms-2 badge badge-order-import rounded-pill">Đã nhập kho</span>;
  }

  if (orderDetail.status == CommonConstant.ORDER_STATUS_SUCC) {
    return <span className="ms-2 badge badge-order-complete rounded-pill">Giao thành công</span>;
  }

  if (orderDetail.status == CommonConstant.ORDER_STATUS_SUCC) {
    return <span className="ms-2 badge badge-order-complete rounded-pill">Giao thành công</span>;
  }

  if (orderDetail.status == CommonConstant.ORDER_STATUS_CANCELED) {
    return <span className="ms-2 badge bg-label-secondary rounded-pill">Đã hủy</span>;
  }

  return (
    <>
      {status == CommonConstant.ORDER_STATUS_EXPORT && (
        <span className="ms-2 badge badge-order-export rounded-pill">
          Đã xuất kho{' '}
          {totalExport < orderDetail.numberParcel && (
            <>
              {' '}
              {totalExport} / {orderDetail.numberParcel} kiện
            </>
          )}
        </span>
      )}

      {status == CommonConstant.ORDER_STATUS_DELIVERING && (
        <span className="ms-2 badge badge-order-delivering rounded-pill">
          Đang giao{' '}
          {totalDelivering < orderDetail.numberParcel && (
            <>
              {' '}
              {totalDelivering} / {orderDetail.numberParcel} kiện
            </>
          )}
        </span>
      )}

      {status == CommonConstant.ORDER_STATUS_CC && (
        <span className="ms-2 badge badge-order-cc rounded-pill">
          Đã thông quan{' '}
          {totalCc < orderDetail.numberParcel && (
            <>
              {' '}
              {totalCc} / {orderDetail.numberParcel} kiện
            </>
          )}
        </span>
      )}
      {status == CommonConstant.ORDER_STATUS_SUCC && (
        <span className="ms-2 badge badge-order-complete rounded-pill">
          {/* {allSuccess < orderDetail.shipments.length} */}
          {(totalSucc < orderDetail.numberParcel || allSuccess < orderDetail.shipments.length) && (
            <>
              Đã giao {totalSucc} / {orderDetail.numberParcel} kiện
            </>
          )}
          {totalSucc >= orderDetail.numberParcel && allSuccess >= orderDetail.shipments.length && <>Giao thành công</>}
        </span>
      )}

    </>
  );
}
