import { RoleConstant } from '../../core/constants/role.constant';

export default function ViewRole({ roleId }: any) {
  if (roleId == RoleConstant.ROLE_ADMIN) return <span className="badge rounded-pill bg-label-primary me-1">Người quản trị</span>;
  if (roleId == RoleConstant.ROLE_CUSTOMER) return <span className="badge rounded-pill bg-label-secondary me-1">Khách hàng</span>;
  if (roleId == RoleConstant.ROLE_SELLER) return <span className="badge rounded-pill bg-label-warning me-1">Nhân viên bán hàng</span>;
  if (roleId == RoleConstant.ROLE_WHTQ) return <span className="badge rounded-pill bg-label-info me-1">Quản lý kho TQ</span>;
  if (roleId == RoleConstant.ROLE_WHVN) return <span className="badge rounded-pill bg-label-success me-1">Quản lý kho VN</span>;
  return <></>
}
