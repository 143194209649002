import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../libs/hook';
import { AuthenticationService } from '../../../core/services/common/authentication.service';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { AuthConstant } from '../../../core/constants/auth.constant';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { useNavigate } from 'react-router-dom';
import FormFloatingPassword from '../../../components/ui/form/FormFloatingPassword';
import { RoleConstant } from '../../../core/constants/role.constant';

export default function Login() {
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loginRequest, setLoginRequest] = useState<any>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedUsername = cookies.get('username') ? cookies.get('username') + '' : '';
    const savedPassword = cookies.get('password') ? cookies.get('password') + '' : '';
    if (savedUsername && savedUsername.trim().length > 0 && savedPassword && savedPassword.trim().length > 0) {
      let temp = {
        username: savedUsername,
        password: savedPassword
      };
      setLoginRequest({ ...temp });
      setRememberMe(true);
    }
  }, []);

  const onChange = (event: any) => {
    loginRequest[event.target.name] = event.target.value;
    setLoginRequest({ ...loginRequest });
  };

  const handleLogin = () => {
    setSubmitted(true);
    if (!loginRequest.username || loginRequest.username?.trim().length <= 0 || !loginRequest.password || loginRequest.password?.trim().length <= 0) {
      return;
    }
    if (rememberMe) {
      cookies.set('username', loginRequest.username);
      cookies.set('password', loginRequest.password);
    } else {
      cookies.remove('username');
      cookies.remove('password');
    }

    dispatch(showSpinner());
    AuthenticationService.login(loginRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          cookies.set(AuthConstant.ACCESS_TOKEN, res.data.responseData.jwt);
          if (res.data.responseData?.roles?.includes(RoleConstant.ROLE_ADMIN) || res.data.responseData?.roles?.includes(RoleConstant.ROLE_WHTQ) || res.data.responseData?.roles?.includes(RoleConstant.ROLE_WHVN)) {
            navigate('/dashboard');
          } else {
            navigate('/order-mgt');
          }
          return;
        }
        toast.error('Số điện thoại hoặc mật khẩu không chính xác.');
      });
  };

  const onChangeRememberMe = (event: any) => {
    setRememberMe(event.target.checked);
  };

  return (
    <>
      <div className="position-relative">
        <div className="authentication-wrapper authentication-basic container-p-y p-4 p-sm-0">
          <div className="authentication-inner py-6">
            <div className="card p-md-7 p-1">
              <div className="app-brand justify-content-center mt-5">
                <a href="index.html" className="app-brand-link gap-2">
                  <img src="/assets/img/logo.jpg" alt="Logo" style={{width: '250px', height: 'auto'}}/>
                </a>
              </div>

              <div className="card-body mt-1">
                <p className="mb-5">Vui lòng đăng nhập vào tài khoản của bạn và bắt đầu.</p>
                <form className="mb-5">
                  <div className="form-floating form-floating-outline mb-5">
                    <input
                      type="text"
                      className={'form-control' + (submitted && (!loginRequest.username || loginRequest.username?.trim().length <= 0) ? ' is-invalid' : '')}
                      name="username"
                      placeholder="Enter your email or username"
                      onChange={(e) => onChange(e)}
                      value={loginRequest.username || ''}
                    />
                    {submitted && (!loginRequest.username || loginRequest.username?.trim().length <= 0) && <div className="error-feedback"> Username is required. </div>}
                    <label htmlFor="email">Tên tài khoản</label>
                  </div>
                  <div className="mb-5">
                    <FormFloatingPassword
                      name="password"
                      onChange={(e) => onChange(e)}
                      label="Mật khẩu"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      isError={submitted && (!loginRequest.password || loginRequest.password?.trim().length <= 0)}
                      errMsg="Password is required."
                      value={loginRequest.password || ''}
                    ></FormFloatingPassword>
                  </div>
                  <div className="mb-5 d-flex justify-content-between mt-5">
                    <div className="form-check mt-2">
                      <input className="form-check-input" type="checkbox" checked={rememberMe ? true : false} id="remember-me" onChange={(e) => onChangeRememberMe(e)} />
                      <label className="form-check-label" htmlFor="remember-me">
                        Nhớ mật khẩu
                      </label>
                    </div>
                    <a onClick={() => {}} className="float-end mb-1 mt-2">
                      <span>Quên mật khẩu?</span>
                    </a>
                  </div>
                  <div className="mb-5">
                    <button className="btn btn-primary d-grid w-100" type="button" onClick={handleLogin}>
                      Đăng nhập
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
