import { toast } from 'react-toastify';
import UploadFile from '../../../components/ui/UploadFile';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { ShipmentService } from '../../../core/services/shipment.service';
import Currency from '../../../components/common/Currency';
import NumberFormat from '../../../components/common/NumberFormat';
import { RoleConstant } from '../../../core/constants/role.constant';
import { userInfoSelector } from '../../../libs/selectors/userSelector';

type SuccFormProps = {
  orderDetail: any;
  onSubmit?: (event: any) => void;
  onCancel?: (event?: any) => void;
  // shipments: any[];
  shipment: any;
};

export default function SuccForm({ onCancel, onSubmit, orderDetail, shipment }: SuccFormProps) {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userInfoSelector);
  const [files, setFiles] = useState<any[]>([]);
  const [shipmentIds, setShipmentIds] = useState<any[]>([]);

  const onSubmitForm = () => {
    if (!files || files?.length <= 0) {
      toast.error('Chưa upload hình ảnh');
      return;
    }
    let ccRequest = {
      shipmentId: shipment.id,
      orderId: orderDetail.orderId
    };
    dispatch(showSpinner());
    ShipmentService.succShipment(ccRequest, files)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          onSubmit?.(ccRequest);
          toast.success('Cập nhật giao hàng thành công');
        } else {
          toast.error('Đã có lỗi xảy tra trong quá trình xử lý, vui lòng thử lại');
        }
      });
  };

  const onChangeFile = (event: any) => {
    setFiles(event.filesToAdd);
  };

  return (
    <>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 py-3 d-flex">
            <div className="accordion-header border-0">
              <div role="button" className="accordion-button pe-0 shadow-none collapsed">
                <div className="d-flex align-items-center">
                  <div className="avatar-wrapper">
                    <div className="avatar me-4">
                      <span className="avatar-initial rounded-circle bg-label-secondary">
                        <i className="ri-car-line ri-24px"></i>
                      </span>
                    </div>
                  </div>
                  <span className="d-flex flex-column fw-normal">
                    <span className="text-heading mb-1">
                      <span className="fw-semibold">{shipment.licensePlate}</span>
                      {shipment.vehicleType && <span className="fw-semibold"> - {shipment.vehicleType}</span>}
                      {shipment.borderGate && <span className="fw-semibold"> - {shipment.borderGate}</span>}
                      {shipment.address && <span className="fw-semibold"> - {shipment.address}</span>}
                    </span>
                    <div>
                      <div className="py-1 d-inline-block justify-content-between">
                        <span>Số kiện</span>
                        <span className="fw-semibold ps-3">{shipment.numberParcel}</span>
                      </div>
                      {shipment.weight > 0 && (
                        <div className="py-1 d-inline-block justify-content-between ps-5">
                          <span>Số cân</span>
                          <span className="fw-semibold ps-3">{shipment.weight ? <>{shipment.weight} kg</> : '-'}</span>
                        </div>
                      )}
                      {shipment.size > 0 && (
                        <div className="py-1 d-inline-block justify-content-between ps-5">
                          <span>Số khối</span>
                          <span className="fw-semibold ps-3">
                            {shipment.size ? (
                              <>
                                {shipment.size} m<sup>3</sup>
                              </>
                            ) : (
                              '-'
                            )}
                          </span>
                        </div>
                      )}
                      {shipment.piece > 0 && (
                        <div className="py-1 d-inline-block justify-content-between ps-5">
                          <span>Số cái</span>
                          <span className="fw-semibold ps-3">{shipment.piece ? shipment.piece : '--'}</span>
                        </div>
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_SELLER) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && shipment.shippingFee > 0 && (
            <div className="col-12">
              <div className="d-flex justify-content-between field-info">
                <div>Phí vận chuyển</div>
                <div>
                  <Currency price={shipment.shippingFee ? shipment.shippingFee : 0}></Currency>
                </div>
              </div>
            </div>
          )}

          {(shipment.domesticFee > 0 || shipment.towingFee > 0 || shipment.forkliftFee > 0 || shipment.woodenFee > 0 || shipment.payOnHehalf > 0) && (
            <div className="col-12">
              <div className="py-3 border-bottom">
                <div>Cước phụ</div>
                <div className="ps-10 mt-2">
                  <div className="row">
                    {shipment.domesticFee > 0 && (
                      <div className="col-sm-12 col-lg-6">
                        <div className="d-flex justify-content-between field-info">
                          <div>Cước nội địa</div>
                          <div>
                            <Currency price={shipment.domesticFee ? shipment.domesticFee : 0}></Currency>
                          </div>
                        </div>
                      </div>
                    )}

                    {shipment.towingFee > 0 && (
                      <div className="col-sm-12 col-lg-6">
                        <div className="d-flex justify-content-between field-info">
                          <div>Kéo xe</div>
                          <div>
                            <Currency price={shipment.towingFee ? shipment.towingFee : 0}></Currency>
                          </div>
                        </div>
                      </div>
                    )}

                    {shipment.forkliftFee > 0 && (
                      <div className="col-sm-12 col-lg-6">
                        <div className="d-flex justify-content-between field-info">
                          <div>Xe nâng</div>
                          <div>
                            <Currency price={shipment.forkliftFee ? shipment.forkliftFee : 0}></Currency>
                          </div>
                        </div>
                      </div>
                    )}

                    {shipment.woodenFee > 0 && (
                      <div className="col-sm-12 col-lg-6">
                        <div className="d-flex justify-content-between field-info">
                          <div>Đóng gỗ</div>
                          <div>
                            <Currency price={shipment.woodenFee ? shipment.woodenFee : 0}></Currency>
                          </div>
                        </div>
                      </div>
                    )}

                    {shipment.payOnHehalf > 0 && (
                      <div className="col-sm-12 col-lg-6">
                        <div className="d-flex justify-content-between field-info">
                          <div>Thanh toán hộ</div>
                          <div>
                            <Currency price={shipment.payOnHehalf ? shipment.payOnHehalf : 0}></Currency>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_SELLER) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
                <div className="pt-3 d-flex justify-content-between field-info ">
                  <div>Tổng tiền</div>
                  <div className="fw-semibold">
                    <Currency price={shipment.totalFee}></Currency>
                  </div>
                </div>
              )}

              <div className="pt-3 d-flex justify-content-between field-info">
                <div className="fs-5 fw-semibold">Số tiền cần thanh toán</div>
                <div className="text-danger fw-semibold">
                  <Currency price={shipment.payAmount ? shipment.payAmount : 0}></Currency>
                </div>
              </div>
            </div>
          )}

          <div className="col-12 pt-8 pb-3">
            <div className="text-heading mb-2">Hình ảnh chuyến hàng</div>
            <UploadFile onChange={onChangeFile} multiple accept='image/*'></UploadFile>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => onCancel?.(false)}>
          Hủy
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onSubmitForm}>
          Xác nhận giao thành công
        </button>
      </div>
    </>
  );
}
