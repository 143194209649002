import axios from 'axios';

export const DashboardService = {

    getInventoryStatistics() {
        const url = process.env.REACT_APP_API_URL + '/dashboard/get-inventory-statistics';
        return axios.get(url);
    },

    getShipmentStatistics() {
        const url = process.env.REACT_APP_API_URL + '/dashboard/get-shipment-statistics';
        return axios.get(url);
    },

    getRevenueStatistics(year: any) {
        const url = process.env.REACT_APP_API_URL + '/dashboard/get-revenue-statistics-by-year?year=' + year;
        return axios.get(url);
    },

    getDashboardData(year: any) {
        const url = process.env.REACT_APP_API_URL + '/dashboard/get-dashboard-data?year=' + year;
        return axios.get(url);
    },

    getYears() {
        const url = process.env.REACT_APP_API_URL + '/dashboard/get-years';
        return axios.get(url);
    },

    getCurrentOverview() {
        const url = process.env.REACT_APP_API_URL + '/dashboard/get-current-overview';
        return axios.get(url);
    }
};
