import { useEffect, useState } from 'react';
import FormFloating from '../../../components/ui/form/FormFloating';
import FormFloatingPassword from '../../../components/ui/form/FormFloatingPassword';
import { RoleConstant } from '../../../core/constants/role.constant';
import { UserService } from '../../../core/services/user.service';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

type AddOrEditUserProps = {
  onClose?: (event?: any) => void;
  user?: any;
};

const schema = yup
  .object({
    username: yup.string().required('Tên tài khoản là bắt buộc'),
    fullName: yup.string().required('Họ và tên là bắt buộc'),
    password: yup.string().required('Mật khẩu là bắt buộc'),
    confirmPassword: yup.string().required('Xác nhận mật khẩu là bắt buộc'),
    phoneNumber: yup
      .string()
      .required('Số điện thoại là bắt buộc')
      .matches(/^[0-9]+$/, 'Số điện thoại chỉ bao gồm chữ số')
      .length(10, 'Số điện thoại phải có 10 chữ số'),
    roleIds: yup.array().required('Vai trò là bắt buộc')
  })
  .required();

export default function AddOrEditUser(props: AddOrEditUserProps) {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const dispatch = useAppDispatch();
  const [collectionRole, setCollectionRole] = useState<any[]>(RoleConstant.COLLECTION_ROLE);
  const [roleIds, setRoleIds] = useState<any[]>([]);

  useEffect(() => {
    if (props.user && props.user?.userUid) {
      setDefaultValue(props.user);
    }
  }, [props.user]);

  const setDefaultValue = (data: any) => {
    setValue('fullName', data.fullName);
    setValue('phoneNumber', data.phoneNumber);
    setValue('username', data.username);

    setValue('password', data.phoneNumber);
    setValue('confirmPassword', data.phoneNumber);
    
    let roleIdsTemp = data.roles?.map((role: any) => role.roleId);
    setRoleIds(roleIdsTemp);
    setValue("roleIds", roleIdsTemp);
  };

  const onChangeRole = (event: any) => {
    let temps = JSON.parse(JSON.stringify(roleIds));
    if (event.target.checked) {
      if (!temps.includes(event.target.value)) temps.push(event.target.value);
    } else {
      temps = temps.filter((roleId: any) => roleId != event.target.value);
    }
    setRoleIds(temps);
    setValue("roleIds", temps);
    if (temps.length > 0 ) {
      clearErrors("roleIds");
    }
  };

  const onSubmit = (data: any) => {
    if (props.user) {
      handleEditUser(data);
    } else {
      handleCreateUser(data);
    }
  };

  const handleCreateUser = (data: any) => {
    if (data.password != data.confirmPassword) {
      toast.error('Xác nhận mật khẩu không trùng khớp');
      return;
    }
    dispatch(showSpinner());
    UserService.createUser(data)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.message);
          return;
        }
        toast.success('Tạo người dùng thành công');
        props.onClose?.(true);
      });
  };

  const handleEditUser = (data: any) => {
    dispatch(showSpinner());
    data.userUid = props.user.userUid;
    UserService.editUser(data)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.message);
          return;
        }
        toast.success('Cập nhật thành công');
        props.onClose?.(true);
      });
  };

  return (
    <>
      <div className="modal-body px-10 py-8">
        <form className="row g-5 fv-plugins-bootstrap5 fv-plugins-framework">
          <div className="col-12 col-md-6 fv-plugins-icon-container">
            <FormFloating label="Tên tài khoản" placeholder="Tên tài khoản" {...register('username')} isError={errors.username ? true : false} errMsg={errors.username?.message} required disabled={props.user ? true : false}></FormFloating>
          </div>
          <div className="col-12 col-md-6 fv-plugins-icon-container">
            <FormFloating label="Họ và tên" placeholder="Họ và tên" {...register('fullName')} isError={errors.fullName ? true : false} errMsg={errors.fullName?.message} required></FormFloating>
          </div>
          {!props.user && (
            <>
              <div className="col-12 col-md-6">
                <FormFloatingPassword label="Mật khẩu" placeholder="Mật khẩu" {...register('password')} isError={errors.password ? true : false} errMsg={errors.password?.message} required></FormFloatingPassword>
              </div>
              <div className="col-12 col-md-6">
                <FormFloatingPassword label="Xác nhận mật khẩu" placeholder="Xác nhận mật khẩu" {...register('confirmPassword')} isError={errors.confirmPassword ? true : false} errMsg={errors.confirmPassword?.message} required></FormFloatingPassword>
              </div>
            </>
          )}

          <div className="col-12">
            <FormFloating type="text" maxLength={10} label="Số điện thoại" placeholder="Số điện thoại" {...register('phoneNumber')} isError={errors.phoneNumber ? true : false} errMsg={errors.phoneNumber?.message} required></FormFloating>
          </div>
          <div className="col-12 col-md-6">
            <div className="text-light fw-medium d-block">
              Vai trò <span className="error-feedback"> * </span> {roleIds.length <= 0 ? <span className="error-feedback">Vai trò là bắt buộc</span> : ''}
            </div>
            {collectionRole.map((item, idx) => (
              <div key={idx} className="form-check form-check-primary mt-4">
                <input className="form-check-input" type="checkbox" checked={roleIds.includes(item.value) ? true : false} value={item.value} id={`radioRole-${idx}`} onChange={onChangeRole} />
                <label className="text-light fw-medium form-check-label" htmlFor={`radioRole-${idx}`}>
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => props.onClose?.()}>
          Hủy
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={handleSubmit(onSubmit)}>
          Lưu
        </button>
      </div>
    </>
  );
}
