export default function Error500() {
  return (
    <div className="misc-wrapper">
      <h4 className="mb-2 mx-2">Đang bảo trì! 🚧</h4>
      <p className="mb-6 mx-sm-2 text-center">Xin lỗi vì sự bất tiện này nhưng hiện tại chúng tôi đang thực hiện một số bảo trì</p>
      <div className="d-flex justify-content-center mt-9">
        <img src="/assets/img/illustrations/misc-under-maintenance-object.png" alt="misc-under-maintenance" className="img-fluid misc-object d-none d-lg-inline-block" width="170" />
        <img src="/assets/img/illustrations/misc-bg-light.png" alt="misc-under-maintenance" className="misc-bg d-none d-lg-inline-block" />
        <div className="d-flex flex-column align-items-center z-3">
          <img src="/assets/img/maintenance.png" alt="misc-under-maintenance" className="img-fluid z-1" width="290" />
          <div>
            <a  className="btn btn-primary text-center my-10 waves-effect waves-light text-white">
              Quay lại trang chủ
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
