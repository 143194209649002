import axios from 'axios';

export const NotificationService = {

    getNotifications(request: any) {
        const url = `${process.env.REACT_APP_API_URL}/notification/get-all`;
        return axios.post(url, request);
    },

    countNotiUnread() {
        const url = `${process.env.REACT_APP_API_URL}/notification/count-noti-unread`;
        return axios.get(url);
    },

    deleteById(id: any) {
        const url = `${process.env.REACT_APP_API_URL}/notification/delete-by-id?id=${id}`;
        return axios.get(url);
    },

    markAsReadById(id: any) {
        const url = `${process.env.REACT_APP_API_URL}/notification/mark-as-read-by-id?id=${id}`;
        return axios.get(url);
    },

    markAllAsReadByCurrentUser() {
        const url = `${process.env.REACT_APP_API_URL}/notification/mark-all-as-read-by-current-user`;
        return axios.get(url);
    },
};
