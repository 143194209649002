import { useEffect, useState } from 'react';
import { PaginationConfig } from '../../core/config/PaginationConfig';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import Pagination from '../../components/ui/Pagination';
import { OrderService } from '../../core/services/order.service';
import FormFloating from '../../components/ui/form/FormFloating';
import { useLocation, useNavigate } from 'react-router-dom';

export default function InvMgt() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchRequest, setSearchRequest] = useState<any>({});
  const [orders, setOrders] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [inventoryStatistics, setInventoryStatistics] = useState<any>({});

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      window.history.replaceState(null, '');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    getInventoryStatistics();
    if (location.state) {
      let cacheSearch = Object.assign({}, location.state);
      if (!cacheSearch.size || cacheSearch.size <= 0) cacheSearch.size = PaginationConfig.PAGE_SIZE;
      if (!cacheSearch.page) cacheSearch.page = 0;
      if (!cacheSearch.offset) cacheSearch.offset = 0;
      setSearchRequest({ ...cacheSearch });
      getOrderByPage(cacheSearch);
    } else {
      searchRequest.size = PaginationConfig.PAGE_SIZE;
      searchRequest.offset = 0;
      searchRequest.page = 0;
      searchRequest.orderId = null;
      searchRequest.customer = null;
      searchRequest.seller = null;
      searchRequest.numParcel = null;
      searchRequest.phoneNumber = null;
      searchRequest.status = null;
      setSearchRequest({ ...searchRequest });
      getOrderByPage(searchRequest);
    }
  };

  const getOrderByPage = (params: any) => {
    dispatch(showSpinner());
    OrderService.getInventories(params)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        let orderNumber = params.page * params.size + 1;
        res.data?.forEach((item: any) => {
          item.no = orderNumber;
          orderNumber++;
        });
        setOrders(res.data);
        setTotalRecords(res.headers['x-total-count']);
      });
  };

  const getInventoryStatistics = () => {
    OrderService.getInventoryStatistics().then((res) => {
      if (res.data.status) {
        let temp = res.data.responseData;
        temp.inventoryNumberParcel = temp.totalNumberParcel - (temp.totalNumberParcelExported ? temp.totalNumberParcelExported : 0);
        temp.inventoryWeight = temp.totalWeight - (temp.totalWeightExported ? temp.totalWeightExported : 0);
        temp.inventorySize = temp.totalSize - (temp.totalSizeExported ? temp.totalSizeExported : 0);
        setInventoryStatistics({ ...temp });
      }
    });
  };

  const onSearch = () => {
    searchRequest.page = 0;
    searchRequest.offset = 0;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const onRefresh = () => {
    searchRequest.size = PaginationConfig.PAGE_SIZE;
    searchRequest.offset = 0;
    searchRequest.page = 0;
    searchRequest.orderId = null;
    searchRequest.customer = null;
    searchRequest.seller = null;
    searchRequest.numParcel = null;
    searchRequest.phoneNumber = null;
    searchRequest.status = null;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const onChangePagination = (event: any) => {
    searchRequest.page = event;
    searchRequest.offset = event * searchRequest.size;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const onViewDetail = (item: any) => {
    navigate(`/inv-mgt/${item.orderId}`, { state: searchRequest });
  };

  const onChangeInput = (event: any) => {
    searchRequest[event.target.name] = event.target.value;
    setSearchRequest({ ...searchRequest });
  };

  const handleRound = (number: any) => {
    const rounded = parseFloat(number.toFixed(2));
    return rounded;
  };

  return (
    <>
      <div className="card mb-6">
        <div className="card-header pb-0">
          <span className="fs-4 fw-bold">Quản lý hàng tồn kho</span>
        </div>
        <div className="card-widget-separator-wrapper">
          <div className="card-body card-widget-separator">
            <div className="row gy-4 gy-sm-1">
              <div className="col-sm-6 col-lg-3">
                <div className="d-flex justify-content-between align-items-center card-widget-1 border-end pb-4 pb-sm-0">
                  <div>
                    <p className="mb-0">Số đơn tồn kho</p>
                    <h4 className="mb-0">{totalRecords}</h4>
                  </div>
                  <div className="avatar me-sm-6">
                    <span className="avatar-initial rounded text-heading">
                      <i className="ri-list-unordered ri-26px"></i>
                    </span>
                  </div>
                </div>
                <hr className="d-none d-sm-block d-lg-none me-6"></hr>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="d-flex justify-content-between align-items-center card-widget-2 border-end pb-4 pb-sm-0">
                  <div>
                    <p className="mb-0">Số kiện tồn kho</p>
                    <h4 className="mb-0">
                      {inventoryStatistics.inventoryNumberParcel >= 0 ? inventoryStatistics.inventoryNumberParcel : 0}
                      <span className="fs-6"> kiện</span>
                    </h4>
                  </div>
                  <div className="avatar me-lg-6">
                    <span className="avatar-initial rounded text-heading">
                      <i className="ri-archive-drawer-line ri-26px"></i>
                    </span>
                  </div>
                </div>
                <hr className="d-none d-sm-block d-lg-none"></hr>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="d-flex justify-content-between align-items-center border-end pb-4 pb-sm-0 card-widget-3">
                  <div>
                    <p className="mb-0">Số cân tồn kho</p>
                    <h4 className="mb-0">
                      {handleRound(inventoryStatistics.inventoryWeight >= 0 ? inventoryStatistics.inventoryWeight : 0)}
                      <span className="fs-6"> kg</span>
                    </h4>
                  </div>
                  <div className="avatar me-sm-6">
                    <span className="avatar-initial rounded text-heading">
                      <i className="ri-weight-line ri-26px"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-0">Số khối tồn kho</p>
                    <h4 className="mb-0">
                      {handleRound(inventoryStatistics.inventorySize >= 0 ? inventoryStatistics.inventorySize : 0)}
                      <span className="fs-6">
                        {' '}
                        m<sup>3</sup>
                      </span>
                    </h4>
                  </div>
                  <div className="avatar">
                    <span className="avatar-initial rounded text-heading">
                      <i className="ri-instance-line ri-26px"></i>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-6 col-lg-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-0">Số khối tồn</p>
                    <h4 className="mb-0">32</h4>
                  </div>
                  <div className="avatar">
                    <span className="avatar-initial rounded text-heading">
                      <i className="ri-error-warning-line ri-26px"></i>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-6">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-2">
              <FormFloating label="Mã đơn hàng" name="orderId" value={searchRequest.orderId || ''} placeholder="Mã đơn hàng" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <FormFloating label="Mã khách hàng" name="customer" value={searchRequest.customer || ''} placeholder="Mã khách hàng" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <FormFloating label="Mã người bán" name="seller" value={searchRequest.seller || ''} placeholder="Mã người bán" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <FormFloating type="number" label="Số kiện" name="numParcel" value={searchRequest.numParcel || ''} placeholder="Số kiến" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <FormFloating type="number" label="Số điện thoại" name="phoneNumber" value={searchRequest.phoneNumber || ''} placeholder="Số điện thoại" onChange={onChangeInput}></FormFloating>
            </div>
            {/* <div className="col-lg-2">
            <FormFloatingSelect defaultValue={searchRequest.status} onChange={onChangeStatus} sources={orderStatusList} bindLabel="label" bindValue="value" label="Chọn trạng thái đơn hàng" placeholder="Trạng thái đơn hàng"></FormFloatingSelect>
          </div> */}
            <div className="col-lg-2">
              <button type="button" className="btn rounded-pill btn-icon btn-secondary btn-fab demo waves-effect waves-light me-2">
                <span className="tf-icons ri-reset-right-line ri-22px" onClick={onRefresh}></span>
              </button>
              <button type="button" className="btn rounded-pill btn-icon btn-primary btn-fab demo waves-effect waves-light" onClick={onSearch}>
                <span className="tf-icons ri-search-line ri-22px"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Mã đơn hàng</th>
                  <th>Số kiện tồn</th>
                  <th>Tổng kiện</th>
                  <th>Loại hàng hóa</th>
                  <th>Mã KH</th>
                  <th>Mã người bán</th>
                  <th>SĐT</th>

                  {/* <th>Số cân</th>
                  <th>Số khối</th>
                  <th>Số cái</th> */}
                  {/* <th>Thông tin xe</th> */}
                  {/*<th>Trạng thái</th>*/}
                  <th>Ghi chú</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {orders.map((item, idx) => (
                  <tr key={idx} onClick={() => onViewDetail(item)}>
                    <td>{item.no}</td>
                    <td>
                      {item.orderId}{' '}
                      {/* <span>
                        {item.warningYn && (
                          <span className="ms-2">
                            <i data-tooltip-id="my-tooltip" data-tooltip-content={item.warningMsg + '!'} className="fs-5 text-warning ri-error-warning-line"></i>
                            <Tooltip id="my-tooltip" style={{ backgroundColor: '#fff3dd', color: '#fdb528', zIndex: '9999', fontStyle: 'italic' }} />
                          </span>
                        )}
                      </span> */}
                    </td>

                    <td>{item.numberParcel - item.totalNumberParcelExported}</td>
                    <td>{item.numberParcel}</td>
                    <td>{item.productType}</td>

                    <td>{item.customerId ? item.customerId : '--'}</td>
                    <td>{item.sellerId ? item.sellerId : '--'}</td>
                    <td>{item.recipientPhoneNumber} </td>

                    {/* <td>{item.weight ? item.weight : '--'}</td>
                    <td>{item.size ? item.size : '--'}</td>
                    <td>{item.piece ? item.piece : '--'}</td> */}
                    {/* <td><ViewShipment shipments={item.shipments}></ViewShipment></td> */}
                    {/*<td>*/}
                    {/*  <ViewOrderStatus orderDetail={item}></ViewOrderStatus>*/}
                    {/*</td>*/}
                    <td>{item.note}</td>
                  </tr>
                ))}
                {orders.length <= 0 && (
                  <tr>
                    <td colSpan={11}>Không có đơn hàng</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {totalRecords > PaginationConfig.PAGE_SIZE && (
        <div className="row mt-10">
          <div className="col-12 d-flex justify-content-center">
            <Pagination currentPage={searchRequest.page} totalRecords={totalRecords} size={PaginationConfig.PAGE_SIZE} onChange={(data) => onChangePagination(data)}></Pagination>
          </div>
        </div>
      )}
    </>
  );
}
