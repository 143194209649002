'use client';
import { useRef } from 'react';
import { AppStore, makeStore } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";
import { Provider } from 'react-redux';
import LoadingView from '../components/ui/LoadingView';

export function StoreProvider({ children }: { children: React.ReactNode }) {
    const storeRef = useRef<AppStore>();
    if (!storeRef.current) {
        // Create the store instance the first time this renders
        storeRef.current = makeStore();
    }
    const persistedStore = persistStore(storeRef.current);

    return (
        <>
            <Provider store={storeRef.current}>
                <PersistGate loading={<LoadingView></LoadingView>} persistor={persistedStore}>
                    {children}
                </PersistGate>
            </Provider>
        </>
    );
}