import { useEffect, useId, useState } from 'react';
import Flatpickr from 'react-flatpickr';

type DateRangePickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  className?: string;
  isError?: boolean;
  errMsg?: any;
  required?: boolean | undefined;
  ranges?: any;
  onChange?: (event: any) => void;
};

export default function DateRangePicker({ ranges, required, errMsg, isError, label, className, onChange, ...restProps }: DateRangePickerProps) {

  const [dateRange, setDateRange] = useState<any>();

  useEffect(() => {
    setDateRange(ranges);
  }, [ranges])

  const onChangeDate = (event: any) => {
    if (event && event.length >= 2) {
      onChange?.({
        startDate: event[0],
        endDate: event[1]
      });
    }
  };

  return (
    <Flatpickr
      value={dateRange}
      onChange={onChangeDate}
      options={{
        mode: 'range',
        locale: {
          rangeSeparator: ' - '
        },
        dateFormat: 'd/m/Y',
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return <CustomInput defaultValue={defaultValue} inputRef={ref} label={label} {...restProps} />;
      }}
    />
  );
}

const CustomInput = ({ label, value, defaultValue, inputRef, ...props }: any) => {
  const id = useId();
  return (
    <div className="form-floating form-floating-outline">
      <input {...props} defaultValue={defaultValue} ref={inputRef} className="form-control flatpickr-input" id={id} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
