import axios from 'axios';

export const ShipmentService = {
  ccShipment(ccRequest: any) {
    // const formData: FormData = new FormData();
    // for (let i = 0; i < files?.length; i++) {
    //   formData.append('multiparts', files[i]);
    // }
    // formData.append('shipmentJson', JSON.stringify(ccRequest));
    const url = process.env.REACT_APP_API_URL + '/shipment/cc-shipment';
    return axios.post(url, ccRequest);
  },

  succShipment(ccRequest: any, files: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('multiparts', files[i]);
    }
    formData.append('shipmentJson', JSON.stringify(ccRequest));
    const url = process.env.REACT_APP_API_URL + '/shipment/succ-shipment';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  deliveringShipment(request: any, files: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('multiparts', files[i]);
    }
    formData.append('shipmentJson', JSON.stringify(request));
    const url = process.env.REACT_APP_API_URL + '/shipment/delivering-shipment';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  exportShipment(request: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/export-shipment';
    return axios.post(url, request);
  },

  getByIdAndOrderId(shipmentId: any, orderId: any) {
    const url = process.env.REACT_APP_API_URL + `/shipment/get-by-id-and-orderid?shipmentId=${shipmentId}&orderId=${orderId}`;
    return axios.post(url);
  },

  editExportShipment(request: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/edit-export-shipment';
    return axios.post(url, request);
  },
  
  getAddressByByLicensePlate(licensePlate: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/get-add-by-license-plate?licensePlate=' + licensePlate;
    return axios.get(url);
  },

  getVehicles(request: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/get-vehicles';
    return axios.post(url, request);
  },

  ccShipmentByLicensePlate(licensePlate: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/cc-shipment-by-license-plate?licensePlate=' + licensePlate;
    return axios.post(url);
  },
  
  getVehicleByLicensePlate(licensePlate: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/get-vehicle-by-license-plate?licensePlate=' + licensePlate;
    return axios.get(url);
  },

  getVehicleDetailByLicensePlate(licensePlate: any) {
    const url = process.env.REACT_APP_API_URL + '/shipment/get-vehicle-detail-by-license-plate?licensePlate=' + licensePlate;
    return axios.get(url);
  },

  deliveringAllShipment(request: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < request.files?.length; i++) {
      let item = request.files[i];
      formData.append('multiparts', item.file, item.newFileName);
    }
    let temp = {
      orderId: request.orderId,
      shipmentIds: request.shipmentIds
    }
    formData.append('shipmentJson', JSON.stringify(temp));
    const url = process.env.REACT_APP_API_URL + '/shipment/delivering-list-shipment';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },
};
