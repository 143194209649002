import { useEffect, useState } from 'react';
import Currency from '../../../components/common/Currency';
import NumberFormat from '../../../components/common/NumberFormat';
import { CommonConstant } from '../../../core/constants/common.constant';
import { useAppSelector } from '../../../libs/hook';
import { userInfoSelector } from '../../../libs/selectors/userSelector';
import { RoleConstant } from '../../../core/constants/role.constant';
import SliderImage from '../../../components/common/SliderImage';

type SuccFormProps = {
  orderDetail: any;
  shipment: any;
};

export default function ShipmentDetail({ orderDetail, shipment }: SuccFormProps) {
  const API_URL = process.env.REACT_APP_API_URL;

  const currentUser = useAppSelector(userInfoSelector);
  const [tracking, setTracking] = useState<any>({});
  // const [atchFleSeqs, setAtchFleSeqs] = useState<any[]>([]);
  // const [isShowSliderImage, setIsShowSliderImage] = useState<boolean>(false);

  useEffect(() => {
    if (shipment) {
      let trackingTemp = shipment.trackings[shipment.trackings.length - 1];
      setTracking(trackingTemp);

      // const imageTemps = trackingTemp.trackingImages?.map((item: any) => item.atchFleSeq);
      // setAtchFleSeqs(imageTemps);
    }
  }, [shipment]);

  // const onViewImage = () => {
  //   setIsShowSliderImage(true);
  // }

  return (
    <>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 py-3 d-flex">
            <div className="accordion-header border-0">
              <div role="button" className="accordion-button pe-0 shadow-none collapsed">
                <div className="d-flex align-items-center">
                  <div className="avatar-wrapper">
                    <div className="avatar me-4">
                      <span className="avatar-initial rounded-circle bg-label-secondary">
                        <i className="ri-car-line ri-24px"></i>
                      </span>
                    </div>
                  </div>
                  <span className="d-flex flex-column fw-normal">
                    <span className="text-heading mb-1">
                      <span className="fw-semibold">{shipment.licensePlate}</span>
                      {shipment.vehicleType && <span className="fw-semibold"> - {shipment.vehicleType}</span>}
                      {shipment.borderGate && <span className="fw-semibold"> - {shipment.borderGate}</span>}
                      {shipment.address && <span className="fw-semibold"> - {shipment.address}</span>}
                    </span>
                    <div>
                      {shipment.weight > 0 && (
                        <div className="py-1 d-inline-block justify-content-between">
                          <span>Số cân</span>
                          <span className="fw-semibold ps-3">
                            {shipment.weight ? (
                              <>
                                {' '}
                                <NumberFormat value={shipment.weight}></NumberFormat> kg
                              </>
                            ) : (
                              '-'
                            )}
                          </span>
                        </div>
                      )}
                      {shipment.size > 0 && (
                        <div className="py-1 d-inline-block justify-content-between ps-5">
                          <span>Số khối</span>
                          <span className="fw-semibold ps-3">
                            {shipment.size ? (
                              <>
                                <NumberFormat value={shipment.size}></NumberFormat> m<sup>3</sup>
                              </>
                            ) : (
                              '-'
                            )}
                          </span>
                        </div>
                      )}

                      <div className="py-1 d-inline-block justify-content-between ps-5">
                        <span>Số kiện</span>
                        <span className="fw-semibold ps-3">
                          <NumberFormat value={shipment.numberParcel}></NumberFormat>
                        </span>
                      </div>
                      {shipment.piece > 0 && (
                        <div className="py-1 d-inline-block justify-content-between ps-5">
                          <span>Số cái</span>
                          <span className="fw-semibold ps-3">{shipment.piece ? shipment.piece : '--'}</span>
                        </div>
                      )}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_SELLER) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && shipment.shippingFee > 0 && (
            <div className="col-12">
              <div className="d-flex justify-content-between field-info">
                <div>Phí vận chuyển</div>
                <div>
                  <Currency price={shipment.shippingFee ? shipment.shippingFee : 0}></Currency>
                </div>
              </div>
            </div>
          )}

          <div className="col-12">
            <div className="py-3 border-bottom">
              <div>Cước phụ</div>
              <div className="ps-10 mt-2">
                <div className="row">
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Cước nội địa</div>
                      <div>
                        <Currency price={shipment.domesticFee ? shipment.domesticFee : 0}></Currency>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Kéo xe</div>
                      <div>
                        <Currency price={shipment.towingFee ? shipment.towingFee : 0}></Currency>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Xe nâng</div>
                      <div>
                        <Currency price={shipment.forkliftFee ? shipment.forkliftFee : 0}></Currency>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Đóng gỗ</div>
                      <div>
                        <Currency price={shipment.woodenFee ? shipment.woodenFee : 0}></Currency>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex justify-content-between field-info">
                      <div>Thanh toán hộ</div>
                      <div>
                        <Currency price={shipment.payOnHehalf ? shipment.payOnHehalf : 0}></Currency>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_SELLER) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
              <div className="pt-3 d-flex justify-content-between field-info ">
                <div>Tổng tiền</div>
                <div className="fw-semibold">
                  <Currency price={shipment.totalFee}></Currency>
                </div>
              </div>
            )}

            <div className="pt-3 d-flex justify-content-between field-info">
              <div className="fs-5 fw-semibold">Số tiền cần thanh toán</div>
              <div className="text-danger fw-semibold">
                <Currency price={shipment.payAmount ? shipment.payAmount : 0}></Currency>
              </div>
            </div>
          </div>

          {shipment.status != CommonConstant.SHIPMENT_STATUS_EXPORT && tracking.trackingImages?.length > 0 && (
            <div className="col-12 pt-8 pb-3">
              <div className="text-heading mb-4">Hình ảnh chuyến hàng</div>
              <div className="d-flex">
                {tracking.trackingImages?.map((item: any) => (
                  // onClick={(event) => onViewImage()}
                  <div className="avatar avatar-xl me-3">
                    <img src={`${API_URL}/public/imageView/${item.atchFleSeq}`} className="rounded-3" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* {
        isShowSliderImage && <SliderImage onClose={() => setIsShowSliderImage(false)} atchFleSeqs={atchFleSeqs}></SliderImage>
      } */}
    </>
  );
}
