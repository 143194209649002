import { useEffect, useState } from 'react';
import { PaginationConfig } from '../../core/config/PaginationConfig';
import { useAppDispatch, useAppSelector } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import Pagination from '../../components/ui/Pagination';
import FormFloating from '../../components/ui/form/FormFloating';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShipmentService } from '../../core/services/shipment.service';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { userInfoSelector } from '../../libs/selectors/userSelector';
import { RoleConstant } from '../../core/constants/role.constant';

export default function VehicleMgt() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useAppSelector(userInfoSelector);
  const [searchRequest, setSearchRequest] = useState<any>({});
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  // const [orderStatusList, setOrderStatusList] = useState<any[]>(CommonConstant.COLLECTION_ORDER_STATUS);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      window.history.replaceState(null, '');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (location.state) {
      let cacheSearch = Object.assign({}, location.state);
      if (!cacheSearch.size || cacheSearch.size <= 0) cacheSearch.size = PaginationConfig.PAGE_SIZE;
      if (!cacheSearch.page) cacheSearch.page = 0;
      if (!cacheSearch.offset) cacheSearch.offset = 0;

      setSearchRequest({ ...cacheSearch });
      getVehicleByPage(cacheSearch);
    } else {
      searchRequest.size = PaginationConfig.PAGE_SIZE;
      searchRequest.offset = 0;
      searchRequest.page = 0;
      searchRequest.licensePlate = null;
      searchRequest.dateRequest = null;
      setSearchRequest({ ...searchRequest });
      getVehicleByPage(searchRequest);
    }
  };

  const getVehicleByPage = (params: any) => {
    dispatch(showSpinner());
    ShipmentService.getVehicles(params)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        let orderNumber = params.page * params.size + 1;
        res.data?.forEach((item: any) => {
          item.no = orderNumber;
          orderNumber++;
        });
        setVehicles(res.data);
        setTotalRecords(res.headers['x-total-count']);
      });
  };

  const onSearch = () => {
    searchRequest.page = 0;
    searchRequest.offset = 0;
    setSearchRequest({ ...searchRequest });
    getVehicleByPage(searchRequest);
  };

  const onRefresh = () => {
    searchRequest.size = PaginationConfig.PAGE_SIZE;
    searchRequest.offset = 0;
    searchRequest.page = 0;
    searchRequest.licensePlate = null;
    searchRequest.dateRequest = null;
    setSearchRequest({ ...searchRequest });
    getVehicleByPage(searchRequest);
  };

  const onChangePagination = (event: any) => {
    searchRequest.page = event;
    searchRequest.offset = event * searchRequest.size;
    setSearchRequest({ ...searchRequest });
    getVehicleByPage(searchRequest);
  };

  const onChangeInput = (event: any) => {
    searchRequest[event.target.name] = event.target.value;
    setSearchRequest({ ...searchRequest });
  };

  const onConfirmCC = (item: any) => {
    Swal.fire({
      title: 'Xác nhận thông quan?',
      text: 'Bạn không thể hoàn tác lại được!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Xác nhận!',
      cancelButtonText: 'Hủy'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showSpinner());
        ShipmentService.ccShipmentByLicensePlate(item.licensePlate)
          .finally(() => {
            setTimeout(() => {
              dispatch(hideSpinner());
            }, 500);
          })
          .then((res) => {
            if (res.data.status) {
              toast.success('Thông quan thành công');
              onSearch();
            } else {
              toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
            }
          });
      }
    });
  };

  const onNavigateDetail = (item: any) => {
    navigate(`/vehicles/${item.licensePlate}`, { state: searchRequest });
  };

  return (
    <>
      <div className="card mb-6">
        <div className="card-header">
          <span className="fs-4 fw-bold">Quản lý xe</span>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4">
              <FormFloating label="Biển số xe" name="licensePlate" value={searchRequest.licensePlate || ''} placeholder="Biển số xe" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-3">
              <FormFloating type="date" label="Ngày xuất kho" name="dateRequest" value={searchRequest.dateRequest || ''} placeholder="Ngày xuất kho" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <button type="button" className="btn rounded-pill btn-icon btn-secondary btn-fab demo waves-effect waves-light me-2" onClick={onRefresh}>
                <span className="tf-icons ri-reset-right-line ri-22px"></span>
              </button>
              <button type="button" className="btn rounded-pill btn-icon btn-primary btn-fab demo waves-effect waves-light" onClick={onSearch}>
                <span className="tf-icons ri-search-line ri-22px"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Biến số xe</th>
                  {/* <th>Tên tài xế</th>
                  <th>Số điện thoại</th> */}
                  <th>Loại xe</th>
                  <th>Cửa khẩu</th>
                  <th>Địa chỉ</th>
                  <th>Trạng thái</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {vehicles.map((item, idx) => (
                  <tr key={idx} onDoubleClick={() => onNavigateDetail(item)}>
                    <td>{item.no}</td>
                    <td>{item.licensePlate}</td>

                    {/* <td>{item.driverName ? item.driverName : '--'}</td>
                    <td>{item.driverPhoneNumber ? item.driverPhoneNumber : '--'}</td> */}
                    <td>{item.vehicleType}</td>
                    <td>{item.borderGate}</td>
                    <td>{item.address}</td>
                    <td>
                      {item.status == '09-01' && <span className="ms-2 badge badge-order-export rounded-pill"> Có thể thông quan</span>}
                      {item.status == '09-02' && <span className="ms-2 badge badge-order-cc rounded-pill">Đã thông quan</span>}
                    </td>

                    <td>
                      {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHVN)) && (
                        <>
                          {item.status == '09-01' && (
                            <div className="dropdown">
                              <button className="btn btn-text-secondary rounded-pill text-muted border-0 p-1 waves-effect waves-light" type="button" id="organicSessionsDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ri-more-2-line ri-20px"></i>
                              </button>
                              <div className="dropdown-menu dropdown-menu-end" aria-labelledby="organicSessionsDropdown">
                                <a className="dropdown-item waves-effect" onClick={() => onConfirmCC(item)}>
                                  Xác nhận thông quan
                                </a>
                                <a className="dropdown-item waves-effect">Hủy</a>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
                {vehicles.length <= 0 && (
                  <tr>
                    <td colSpan={11}>Không có đơn hàng</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {totalRecords > PaginationConfig.PAGE_SIZE && (
        <div className="row mt-10">
          <div className="col-12 d-flex justify-content-center">
            <Pagination currentPage={searchRequest.page} totalRecords={totalRecords} size={PaginationConfig.PAGE_SIZE} onChange={(data) => onChangePagination(data)}></Pagination>
          </div>
        </div>
      )}
    </>
  );
}
