import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import CryptoJS from 'crypto-js';
import { CommonService } from '../../core/services/common/common.service';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';

type PinCodeAuthenticationProps = {
  onClose: (event?: any) => void;
};

export default function PinCodeAuthentication(props: PinCodeAuthenticationProps) {
  const dispatch = useAppDispatch();
  const [otp, setOtp] = useState('');

  useEffect(() => {}, []);

  const onVerify = () => {
    if (!otp && otp.length <= 0) {
      return;
    }
    dispatch(showSpinner());
    const hash = CryptoJS.MD5(otp).toString();
    CommonService.verifyPinCode(hash)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error('Mã pin không chính xác');
          return;
        }
        props.onClose(true);
      });
  };

  return (
    <>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <p className="text-start mb-5">Xác thực mã pin để có thể xem được số điện thoại.</p>
            <form id="twoStepsForm" className="fv-plugins-bootstrap5 fv-plugins-framework">
              <div className="mb-3 fv-plugins-icon-container">
                <div className="auth-input-wrapper d-flex align-items-center justify-content-center numeral-mask-wrapper">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={({type, className, style, ...props }) => <input type='number' min={0} style={{ width: '50px' }} className={`form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2 `} {...props} />}
                  />
                </div>
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
              </div>
              <button type="button" className="btn btn-primary d-grid w-100 mb-5 waves-effect waves-light" onClick={onVerify}>
                Xác nhận
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
