import React from "react";

interface IProps {
  price: number;
  currency?: 'RMB' | 'VND'
}

const Currency: React.FC<IProps> = ({ price, currency }) => {
  const formattedPrice = Intl.NumberFormat('vi-VN').format(price);
  return (
    <>
      {currency == 'RMB' && '¥' } {formattedPrice} {currency != 'RMB' && 'đ'}
    </>
  );
}

export default Currency;