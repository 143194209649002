import axios from 'axios';
import { ApiUrlUtil } from '../utils/api-url.util';
import { ParamUtil } from '../utils/param.util';

export const OrderService = {

  getByPage(request: any) {
    const url = process.env.REACT_APP_API_URL + '/order/get-by-page';
    return axios.post(url, request);
  },

  search(request: any) {
    const url = process.env.REACT_APP_API_URL + '/order/search';
    return axios.post(url, request);
  },

  addOrder(order: any, files: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('multiparts', files[i]);
    }
    formData.append('jsonOrderDTO', JSON.stringify(order));
    const url = process.env.REACT_APP_API_URL + '/order/add-order';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  editOrder(request: any) {
    const url = process.env.REACT_APP_API_URL + '/order/edit-order';
    return axios.post(url, request);
  },

  getOrderById(id: any) {
    const url = process.env.REACT_APP_API_URL + `/order/get-by-id/${id}`;
    return axios.get(url);
  },

  delete(orderId: any) {
    const url = process.env.REACT_APP_API_URL + `/order/delete?orderId=${orderId}`;
    return axios.get(url);
  },

  importByFile(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = process.env.REACT_APP_API_URL + '/order/import-by-file';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  createOrUpdateFormFile(excel: any, images: any) {
    const formData: FormData = new FormData();
    formData.append('file', excel);
    for (let i = 0; i < images?.length; i++) {
      formData.append('images', images[i]);
    }
    const url = process.env.REACT_APP_API_URL + '/order/import-by-file';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  createOrUpdateExportInfoByFile(file: any, images: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    // for (let i = 0; i < images?.length; i++) {
    //   formData.append('images', images[i]);
    // }
    const url = process.env.REACT_APP_API_URL + '/order/export-by-file';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  getInventories(request: any) {
    const url = process.env.REACT_APP_API_URL + '/order/get-inventories';
    return axios.post(url, request);
  },

  getInventoryStatistics() {
    const url = process.env.REACT_APP_API_URL + '/order/get-inventory-statistics';
    return axios.get(url);
  },

  exportPdf(request: any) {
    const url = process.env.REACT_APP_API_URL + "/order/export-pdf";
    return axios.post(url, request, {
      responseType: 'blob', 
    });
  },

  deleteByOrderId(orderId: any) {
    const url = process.env.REACT_APP_API_URL + `/order/delete-order?orderId=${orderId}`;
    return axios.get(url);
  },

  exportExcel(request: any) {
    const url = process.env.REACT_APP_API_URL + "/order/export-excel";
    return axios.post(url, request, {
      responseType: 'blob', 
    });
  }

};
