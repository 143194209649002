export class RoleConstant {
  public static readonly ROLE_ADMIN = "ROLE_ADMIN";
  public static readonly ROLE_CUSTOMER = "ROLE_CUSTOMER";
  public static readonly ROLE_SELLER = "ROLE_SELLER";
  public static readonly ROLE_WHTQ = "ROLE_WHTQ";
  public static readonly ROLE_WHVN = "ROLE_WHVN";

  public static readonly COLLECTION_ROLE = [
    { label: 'Người quản trị', value: "ROLE_ADMIN" },
    { label: 'Khách hàng', value: "ROLE_CUSTOMER" },
    { label: 'Nhân viên bán hàng', value: "ROLE_SELLER" },
    { label: 'Quản lý kho TQ', value: "ROLE_WHTQ" },
    { label: 'Quản lý kho VN', value: "ROLE_WHVN" },
    
  ]
}
