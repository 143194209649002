import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrderService } from '../../core/services/order.service';
import moment from 'moment';
import { CommonConstant } from '../../core/constants/common.constant';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';
import ViewOrderStatus from '../../components/common/ViewOrderStatus';
import NumberFormat from '../../components/common/NumberFormat';
import Currency from '../../components/common/Currency';

export default function InvDetail() {
  const url = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [totalNumberParcelExported, setTotalNumberParcelExported] = useState<number>(0);
  const [timelines, setTimeLines] = useState<any[]>([]);

  useEffect(() => {
    getOrder();
  }, [id]);

  const getOrder = () => {
    dispatch(showSpinner());
    OrderService.getOrderById(id)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setOrderDetail(res.data.responseData);
        convertData(res.data.responseData);
      });
  };

  const convertData = (data: any) => {
    let shipmentTrackingTemps: any[] = [];
    let totalNumberPercelTemp = 0;
    let totalShipmentCompletedTemp = 0;
    data.shipments?.forEach((shipment: any, idx: any) => {
      let trackingTemps = shipment.trackings?.map((ele: any) => {
        ele.shipmentInfo = shipment;
        return ele;
      });
      if (shipment.status == CommonConstant.SHIPMENT_STATUS_SUCC) {
        totalShipmentCompletedTemp++;
      }
      shipmentTrackingTemps = [...shipmentTrackingTemps, ...trackingTemps];
      totalNumberPercelTemp += shipment.numberParcel;
    });

    shipmentTrackingTemps.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
    setTimeLines(shipmentTrackingTemps);
    setTotalNumberParcelExported(totalNumberPercelTemp);
  };

  const onBack = () => {
    navigate('/inv-mgt', {state: location.state ? location.state : null});
  }

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 gap-6">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-1">
            <h5 className="mb-0">
              Đơn hàng #<span className="text-primary">{orderDetail.orderId}</span>
            </h5>
            <ViewOrderStatus orderDetail={orderDetail}></ViewOrderStatus>
            {orderDetail.warningYn && (
              <span className="ms-2 text-warning fst-italic">
                <span>** Cảnh báo: {orderDetail.warningMsg}!</span>
              </span>
            )}
            <div className="ms-2 text-primary">Còn tồn: {orderDetail.numberParcel - totalNumberParcelExported} kiện</div>
          </div>
          <p className="mb-0 fst-italic">{moment(orderDetail.whEntryDate).format('YYYY/MM/DD HH:mm')}</p>
        </div>

        <div className="d-flex align-content-center flex-wrap gap-2">
          <button className="btn btn-cancel" onClick={onBack}>
            Quay lại
          </button>
          <button className="btn btn-primary" onClick={() => navigate(`/order-mgt/detail/${id}`)}>
            Chi tiết đơn hàng
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card mb-6">
            <div className="card-body">
              <h5 className="card-title mb-6">Thông tin hàng hóa</h5>
              <div>
                <div className="pb-3 d-flex justify-content-between field-info">
                  <div>Số kiện</div>
                  <div>
                    <NumberFormat value={orderDetail.numberParcel}></NumberFormat>
                  </div>
                </div>
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Số cân</div>
                  <div>
                    {orderDetail.weight ? (
                      <>
                        <NumberFormat value={orderDetail.weight}></NumberFormat> kg
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Số khối</div>
                  <div>
                    {orderDetail.size ? (
                      <>
                        <NumberFormat value={orderDetail.size}></NumberFormat> m<sup>3</sup>
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Số cái</div>
                  <div>{orderDetail.size ? <NumberFormat value={orderDetail.piece}></NumberFormat> : '--'}</div>
                </div>
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Loại hàng hóa</div>
                  <div>{orderDetail.productType ? orderDetail.productType : '--'}</div>
                </div>
                <>
                  {orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT && (
                    <div className="my-3 d-flex justify-content-between field-info">
                      <div>Cước vận chuyển</div>
                      <div className="fw-semibold">
                        <Currency price={orderDetail.shippingFee}></Currency>
                      </div>
                    </div>
                  )}

                  <div className="py-3">
                    <div>Cước phụ</div>
                    <div className="ps-10 mt-2">
                      <div className="row">
                        <div className="cols-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Cước nội địa</div>
                            <div>
                              <Currency price={orderDetail.domesticFee} currency={orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT ? 'RMB' : 'VND'}></Currency>
                            </div>
                          </div>
                        </div>
                        <div className="cols-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Kéo xe</div>
                            <div>
                              <Currency price={orderDetail.towingFee} currency={orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT ? 'RMB' : 'VND'}></Currency>
                            </div>
                          </div>
                        </div>
                        <div className="cols-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Xe nâng</div>
                            <div>
                              <Currency price={orderDetail.forkliftFee} currency={orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT ? 'RMB' : 'VND'}></Currency>
                            </div>
                          </div>
                        </div>
                        <div className="cols-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Đóng gỗ</div>
                            <div>
                              <Currency price={orderDetail.woodenFee} currency={orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT ? 'RMB' : 'VND'}></Currency>
                            </div>
                          </div>
                        </div>
                        <div className="cols-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Thanh toán hộ</div>
                            <div>
                              <Currency price={orderDetail.payOnHehalf} currency={orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT ? 'RMB' : 'VND'}></Currency>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-3 d-flex justify-content-between field-info border-top">
                    <div className="fs-5 fw-semibold">Tổng tiền</div>
                    <div className="text-danger fw-semibold">
                      <Currency price={orderDetail.totalFee ? orderDetail.totalFee : 0} currency={orderDetail.status == CommonConstant.ORDER_STATUS_IMPORT ? 'RMB' : 'VND'}></Currency>
                    </div>
                  </div>
                  {orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT && (
                    <div className="d-flex justify-content-between field-info">
                      <div>Số tiền cần thanh toán</div>
                      <div className="fw-semibold">
                        <Currency price={orderDetail.payAmount ? orderDetail.payAmount : 0} currency="VND"></Currency>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="card mb-6">
            <div className="card-header">
              <h5 className="card-title m-0">Hình ảnh đơn hàng</h5>
            </div>
            <div className="card-body mt-3">
              <ul className="timeline pb-0 mb-0">
                {orderDetail.trackings?.map((tracking: any, idx: any) => (
                  <React.Fragment key={idx}>
                    {idx == 0 && (
                      <li className={'timeline-item timeline-item-transparent ' + (timelines && timelines.length > 0 ? 'border-left-dashed' : 'border-transparent pb-0')}>
                        <div className="timeline-event">
                          <div className="timeline-image">
                            <div className="d-flex">
                              {tracking.trackingImages.map((image: any, idx: any) => (
                                <React.Fragment key={idx}>
                                  <div className="avatar avatar-xl me-3">
                                    <img src={`${url}/public/imageView/${image.atchFleSeq}`} alt="Avatar" className="rounded-3" />
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-6">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title m-0">Thông tin chung</h5>
            </div>
            <div className="card-body ">
              <div className="pb-3 d-flex justify-content-between field-info">
                <div>Mã đơn hàng</div>
                <div>
                  {orderDetail.orderId}{' '}
                  <a
                    onClick={() => {
                      navigator.clipboard.writeText(orderDetail.orderId);
                      toast.success('Đã copy!');
                    }}
                    className="cursor-pointer"
                  >
                    <i className="ri-file-copy-2-line"></i>
                  </a>
                </div>
              </div>
              <div className="py-3 d-flex justify-content-between field-info">
                <div>Mã khách hàng</div>
                <div>{orderDetail.customerId}</div>
              </div>
              <div className="py-3 d-flex justify-content-between field-info">
                <div>Seller</div>
                <div>{orderDetail.sellerId}</div>
              </div>
              <div className="py-3 d-flex justify-content-between field-info">
                <div>SĐT nhận</div>
                <div>
                  {orderDetail.recipientPhoneNumber}{' '}
                  <a
                    onClick={() => {
                      navigator.clipboard.writeText(orderDetail.recipientPhoneNumber);
                      toast.success('Đã copy!');
                    }}
                    className="cursor-pointer"
                  >
                    <i className="ri-file-copy-2-line"></i>
                  </a>
                </div>
              </div>
              {/* <div className="py-3 d-flex justify-content-between field-info">
                <div>Điểm đến</div>
                <div>{orderDetail.address}</div>
              </div> */}
              {orderDetail.note && (
                <div className="py-3 d-flex justify-content-between field-info">
                  <div>Note</div>
                  <div>{orderDetail.note}</div>
                </div>
              )}
            </div>
          </div>

          {/* {orderDetail.shipments?.length > 0 && (
            <div className="card mb-6">
              <div className="card-body">
                <h5 className="card-title mb-3">Thông tin vận chuyển</h5>

                <div className="accordion ps-0 pt-0">
                  {orderDetail.shipments?.map((item: any, idx: any) => (
                    <div key={idx} className="accordion-item shadow-none border-0 my-0 py-2">
                      <div className="accordion-header border-0">
                        <div className="pe-0 shadow-none collapsed">
                          <div className="d-flex align-items-center" style={{ width: '100%' }}>
                            <div className="avatar-wrapper">
                              <div className="avatar me-4">
                                <span className="avatar-initial rounded-circle bg-label-secondary">
                                  <i className="ri-car-line ri-24px"></i>
                                </span>
                              </div>
                            </div>
                            <div className="fw-normal" style={{ width: '100%' }}>
                              <div className="d-flex justify-content-between mb-1">
                                <div className="d-flex align-items-center text-heading">
                                  <div className="fw-semibold">
                                    {item.licensePlate} - {item.vehicleType} - {item.borderGate} - {item.address}
                                  </div>
                                  
                                  <div className="ms-2">
                                    <ViewShipmentStatus status={item.status}></ViewShipmentStatus>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="py-1 d-inline-block justify-content-between field-info">
                                  <span>Số kiện</span>
                                  <span className="fw-semibold">{item.numberParcel ? <NumberFormat value={item.numberParcel}></NumberFormat> : '--'}</span>
                                </div>
                                <div className="py-1 d-inline-block justify-content-between field-info">
                                  <span>Số cân</span>
                                  <span className="fw-semibold">
                                    {item.weight ? (
                                      <>
                                        <NumberFormat value={item.weight}></NumberFormat> kg
                                      </>
                                    ) : (
                                      '--'
                                    )}
                                  </span>
                                </div>
                                <div className="py-1 d-inline-block justify-content-between field-info">
                                  <span>Số khối</span>
                                  <span className="fw-semibold">
                                    {item.size ? (
                                      <>
                                        <NumberFormat value={item.size}></NumberFormat> m<sup>3</sup>
                                      </>
                                    ) : (
                                      '--'
                                    )}
                                  </span>
                                </div>
                                <div className="py-1 d-inline-block justify-content-between field-info">
                                  <span>Số cái</span>
                                  <span className="fw-semibold">{item.piece ? <NumberFormat value={item.piece}></NumberFormat> : '--'}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
