import React, { useCallback, useEffect, useId, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

type UploadFileProps = {
  onChange: (event: any) => void;
  files?: any[];
  // attchmentList?: any[];
  multiple?: boolean | undefined;
  accept?: string | '*';
  onDelete?: (event: any) => void;
};

export default function UploadFile({ accept, multiple, files, onChange }: UploadFileProps) {
  const inputId = useId();
  const fileInputRef: any = useRef(null);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
  const [filesDefault, setFilesDefault] = useState<any[]>([]);

  useEffect(() => {
    if (files && files.length > 0) {
      setFilesDefault(files);
    }
  }, [files]);

  // const onDrop = useCallback((acceptedFiles: any) => {
  //   console.log(acceptedFiles);
  // }, []);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onClickFile = (event: any) => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: any) => {
    const files = Array.from(event.target.files);
    if (multiple) {
      const newFiles = files.filter((file: any) => !selectedImages.some((f) => f.name == file.name && f.size == file.size));
      setSelectedImages((prev) => [...prev, ...newFiles]);
    } else {
      setSelectedImages(files);
    }
  };

  useEffect(() => {
    onChange({ filesToAdd: selectedImages, filesToDelete: filesToDelete });
  }, [selectedImages, filesToDelete]);

  const onRemove = (event: any, idx: any) => {
    event.stopPropagation();
    let temps = selectedImages;
    temps = temps.filter((item, index) => idx != index);
    setSelectedImages(temps);
    let element: any =  document.getElementById(inputId);
    element.value = null; // Reset input field
  };

  const onRemoveAtchFleSeq = (event: any, atchFleSeq: any) => {
    event.stopPropagation();
    let temps: any = filesDefault;
    temps = temps.filter((ele: any) => ele.atchFleSeq != atchFleSeq);
    setFilesDefault(temps);
    setFilesToDelete((prev) => [...prev, ...[atchFleSeq]]);
  };

  return (
    <>
      <div className="dropzone needsclick dz-clickable" onClick={(event) => onClickFile(event)}>
        {selectedImages.length <= 0 && filesDefault.length <= 0 && (
          <div className="dz-message needsclick my-12">
            <div className="d-flex justify-content-center">
              <div className="avatar">
                <span className="avatar-initial rounded-3 bg-label-secondary">
                  <i className="ri-upload-2-line ri-24px"></i>
                </span>
              </div>
            </div>
            <p className="h4 needsclick my-2">Kéo và thả tệp của bạn vào đây</p>
            <small className="note text-muted d-block fs-6 my-2">or</small>
            <span className="needsclick btn btn-sm btn-outline-primary" id="btnBrowse">
              Tải tệp
            </span>
          </div>
        )}

        {filesDefault?.map((file, idx) => (
          <React.Fragment key={idx}>
            <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
              <div className="dz-details">
                <div className="dz-thumbnail">
                  <img src={`${process.env.REACT_APP_API_URL}/public/imageView/${file.atchFleSeq}`} />
                </div>
              </div>
              <a className="dz-remove cursor-pointer" onClick={(event) => onRemoveAtchFleSeq(event, file.atchFleSeq)}>
                Xoá
              </a>
            </div>
          </React.Fragment>
        ))}
        {selectedImages.map((image, idx) => (
          <React.Fragment key={idx}>
            <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
              <div className="dz-details">
                <div className="dz-thumbnail">
                  {/* <img alt={image.name} src={URL.createObjectURL(image)} /> */}
                  {image.type.startsWith('image/') ? <img alt={image.name} src={URL.createObjectURL(image)} /> : <img src="/assets/img/icons/file-type-excel2.svg" />}
                </div>
                <div className="dz-filename">{image.name}</div>
                <div className="dz-size">
                  <strong>{image.size}</strong> KB
                </div>
              </div>
              <a className="dz-remove cursor-pointer" onClick={(event) => onRemove(event, idx)}>
                Xoá
              </a>
            </div>
          </React.Fragment>
        ))}

        <div className="fallback" style={{ display: 'none' }}>
          <input id={inputId} name="file" type="file" ref={fileInputRef} onChange={(event) => handleFileChange(event)} multiple={multiple ? true : false} accept={accept} />
        </div>
      </div>
    </>
  );
}
