import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type LayoutState = {
    ripple: boolean,
    inputStyle: string,
    menuMode: string,
    colorScheme: string,
    theme: string,
    scale: number
};

const initialState = {
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    colorScheme: 'light',
    theme: 'lara-light-indigo',
    scale: 14
} as LayoutState;

export const layoutSlice = createSlice({
  name: "layoutSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    
  },
});

export const { reset } = layoutSlice.actions;
export default layoutSlice.reducer;