import { useState } from 'react';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import FormFloating from '../../components/ui/form/FormFloating';
import { CommonConstant } from '../../core/constants/common.constant';
import { OrderService } from '../../core/services/order.service';

export default function ExportPdf() {
  const dispatch = useAppDispatch();
  const [request, setRequest] = useState<any>({});
  const [pageSizeCollection, setPageSizeCollection] = useState<any[]>(CommonConstant.PAGE_SIZE_COLLECTION);

  const exportPdf = () => {
    if (!request.orderId || request.orderId?.trim().length <= 0 || !request.pageSize) {
      return;
    }
    dispatch(showSpinner());
    OrderService.exportPdf(request)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then(
        (res: any) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link: any = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${request.orderId}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        },
        (error) => {}
      );
  };

  const onChangeInput = (event: any) => {
    request[event.target.name] = event.target.value;
    setRequest({ ...request });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="fs-4 fw-bold">Xuất ảnh theo mã đơn hàng</span>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <FormFloating label="Mã đơn hàng" name="orderId" value={request.orderId || ''} placeholder="Mã đơn hàng" onChange={onChangeInput} required></FormFloating>
            </div>
            <div className="col-lg-6">
              <FormFloating type="number" label="Số kiện" name="numberParcel" value={request.numberParcel || ''} placeholder="Số kiến" onChange={onChangeInput}></FormFloating>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-base text-light fw-medium d-block">
              Kích cỡ <span className="text-danger">*</span>
            </div>
            {pageSizeCollection.map((item, idx) => (
              <div key={idx} className="form-check form-check-inline mt-4">
                <input className="form-check-input" type="radio" name="pageSize" id={'pageSize' + item.value} value={item.value} onChange={onChangeInput} />
                <label className="form-check-label" htmlFor={'pageSize' + item.value}>
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-primary waves-effect waves-light" onClick={exportPdf}>
            Xuất File
          </button>
        </div>
      </div>
    </>
  );
}
