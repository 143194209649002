import axios from 'axios';
import { ParamUtil, RequestParam } from '../../utils/param.util';
import { ApiUrlUtil } from '../../utils/api-url.util';

export const FileService = {

  multiFileUpload(files: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('multiparts', files[i]);
    }
    return axios.post(process.env.REACT_APP_API_URL + '/common-file/multi-file-upload', formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  downloadFile(path: string) {
    var param = {
      atchFleSeqNm: encodeURIComponent(path)
    };
    const params: RequestParam[] = ParamUtil.toRequestParams(param);
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_MAIN_URL + '/common/fileServices/downloadFile.exclude', params);
    return axios.get(url, {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/json' }
    });
  },

  downloadImportTemplate() {
    const url = process.env.REACT_APP_API_URL + '/common-file/download-import-template';
    return axios.get(url, {
      responseType: 'blob',
      // headers: { 'Content-Type': 'application/json' }
    });
  },

  downloadExportTemplate() {
    const url = process.env.REACT_APP_API_URL + '/common-file/download-export-template';
    return axios.get(url, {
      responseType: 'blob',
      // headers: { 'Content-Type': 'application/json' }
    });
  }
};
