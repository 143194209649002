import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

type QrCodeModalProps = {
  value: any;
  onClose?: (event?: any) => void;
};

export default function QrCodeModal(props: QrCodeModalProps) {
  const [value, setValue] = useState<any>('');

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <p className="text-start mb-5">Quét mã để nhận được thông tin mã đơn hàng</p>
            <form id="twoStepsForm" className="fv-plugins-bootstrap5 fv-plugins-framework">
              <div className="mb-3 fv-plugins-icon-container">
                <div className="auth-input-wrapper d-flex align-items-center justify-content-center numeral-mask-wrapper">
                  <QRCode size={256} style={{ height: 'auto', maxWidth: '100%', width: '100%' }} value={value} viewBox={`0 0 256 256`} />
                </div>
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
              </div>
              {/* <button type="button" className="btn btn-primary d-grid w-100 mb-5 waves-effect waves-light">
                Xác nhận
              </button> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
