import { useEffect, useState } from 'react';
import { PaginationConfig } from '../../core/config/PaginationConfig';
import { OrderService } from '../../core/services/order.service';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../components/ui/Pagination';
import { useAppDispatch, useAppSelector } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import AppModel from '../../components/ui/AppModel';
import ImportFileModal from './components/ImportFileModal';
import ViewOrderStatus from '../../components/common/ViewOrderStatus';
import ExportFileModal from './components/ExportFileModel';
import { Tooltip } from 'react-tooltip';
import ViewShipment from '../../components/common/ViewShipment';
import { userInfoSelector } from '../../libs/selectors/userSelector';
import { RoleConstant } from '../../core/constants/role.constant';
import FormFloating from '../../components/ui/form/FormFloating';
import FormFloatingSelect from '../../components/ui/form/FormFloatingSelect';
import { CommonConstant } from '../../core/constants/common.constant';
import DateRangePicker from '../../components/ui/form/DateRangePicker';
import moment from 'moment';

export default function OrderMgt() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userInfoSelector);
  const [searchRequest, setSearchRequest] = useState<any>({});
  const [orders, setOrders] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isOpenImportFileModal, setIsOpenImportFileModal] = useState<boolean>(false);
  const [isOpenExportFileModal, setIsOpenExportFileModal] = useState<boolean>(false);
  const [orderStatusList, setOrderStatusList] = useState<any[]>(CommonConstant.COLLECTION_ORDER_STATUS);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      window.history.replaceState(null, '');
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (location.state) {
      let cacheSearch = Object.assign({}, location.state);
      if (!cacheSearch.size || cacheSearch.size <= 0) cacheSearch.size = PaginationConfig.PAGE_SIZE;
      if (!cacheSearch.page) cacheSearch.page = 0;
      if (!cacheSearch.offset) cacheSearch.offset = 0;

      setSearchRequest({ ...cacheSearch });
      getOrderByPage(cacheSearch);
    } else {
      searchRequest.size = PaginationConfig.PAGE_SIZE;
      searchRequest.offset = 0;
      searchRequest.page = 0;
      searchRequest.keyword = null;
      searchRequest.orderId = null;
      searchRequest.customer = null;
      searchRequest.seller = null;
      searchRequest.numParcel = null;
      searchRequest.phoneNumber = null;
      searchRequest.licensePlate = null;
      searchRequest.status = null;
      searchRequest.startDate = null;
      searchRequest.endDate = null;
      searchRequest.ranges = null;
      setSearchRequest({ ...searchRequest });
      getOrderByPage(searchRequest);
    }
  };

  const getOrderByPage = (params: any) => {
    dispatch(showSpinner());
    OrderService.getByPage(params)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        let orderNumber = params.page * params.size + 1;
        res.data?.forEach((item: any) => {
          item.no = orderNumber;
          orderNumber++;
        });
        setOrders(res.data);
        setTotalRecords(res.headers['x-total-count']);
      });
  };

  const onViewDetail = (order: any) => {
    navigate(`/order-mgt/detail/${order.orderId}`, { state: searchRequest });
  };

  const onChangePagination = (event: any) => {
    searchRequest.page = event;
    searchRequest.offset = event * searchRequest.size;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const onSearch = () => {
    searchRequest.page = 0;
    searchRequest.offset = 0;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const onChangeInput = (event: any) => {
    searchRequest[event.target.name] = event.target.value;
    setSearchRequest({ ...searchRequest });
  };

  const openImportFileModal = () => {
    setIsOpenImportFileModal(true);
  };

  const openExportFileModal = () => {
    setIsOpenExportFileModal(true);
  };

  const onCloseImportFileModal = (event: any) => {
    setIsOpenImportFileModal(false);
    if (event) {
      init();
    }
  };
  const onCloseExportFileModal = (event: any) => {
    setIsOpenExportFileModal(false);
    if (event) {
      init();
    }
  };

  const onChangeStatus = (data: any) => {
    searchRequest.status = data?.value ? data.value : null;
    setSearchRequest({ ...searchRequest });
  };

  const onRefresh = () => {
    // init();
    searchRequest.size = PaginationConfig.PAGE_SIZE;
    searchRequest.offset = 0;
    searchRequest.page = 0;
    searchRequest.keyword = null;
    searchRequest.orderId = null;
    searchRequest.customer = null;
    searchRequest.seller = null;
    searchRequest.numParcel = null;
    searchRequest.phoneNumber = null;
    searchRequest.licensePlate = null;
    searchRequest.status = null;
    searchRequest.startDate = null;
    searchRequest.endDate = null;
    searchRequest.ranges = null;
    setSearchRequest({ ...searchRequest });
    getOrderByPage(searchRequest);
  };

  const exportExcel = () => {
    dispatch(showSpinner());
    OrderService.exportExcel(searchRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then(
        (res: any) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link: any = document.createElement('a');
          link.href = url;
          let fileName = moment(new Date()).format('DDMMyyyy');
          link.setAttribute('download', `export-${fileName}-${new Date().getTime()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        },
        (error) => {}
      );
  };

  const onChangeDateRange = (event: any) => {
    if (event) {
      searchRequest.startDate = new Date(event.startDate);
      searchRequest.endDate = new Date(event.endDate);
      searchRequest.ranges = [event.startDate, event.endDate];
    } else {
      searchRequest.startDate = null;
      searchRequest.endDate = null;
      searchRequest.ranges = null;
    }
    setSearchRequest({ ...searchRequest });
  };

  return (
    <>
      <div className="card mb-6">
        <div className="card-header d-flex justify-content-between">
          <span className="fs-4 fw-bold">Quản lý đơn hàng</span>
          {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHTQ)) && (
            <div className="text-end d-inline-block">
              <div className="btn-group me-3 ms-3">
                <button type="button" className="btn btn-excel" data-bs-toggle="dropdown">
                  <span className="tf-icons ri-file-excel-line ri-16px me-2"></span>Thao tác Excel
                </button>
                <button type="button" className="btn btn-excel dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={() => openImportFileModal()}>
                      Nhập kho
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={() => openExportFileModal()}>
                      Xuất kho
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={() => exportExcel()}>
                      Xuất dữ liệu
                    </a>
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="btn btn-primary btn-fab demo waves-effect waves-light"
                onClick={() => {
                  navigate('/order-mgt/add-order');
                }}
              >
                <span className="tf-icons ri-add-line ri-16px me-2"></span>Nhập hàng
              </button>
            </div>
          )}
        </div>
        <div className="card-body py-5">
          <div className="row gy-4">
            <div className="col-lg-4">
              <FormFloating label="Mã đơn hàng" name="orderId" value={searchRequest.orderId || ''} placeholder="Mã đơn hàng" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-3">
              <FormFloating label="Mã khách hàng" name="customer" value={searchRequest.customer || ''} placeholder="Mã khách hàng" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <FormFloating type="number" label="Số điện thoại" name="phoneNumber" value={searchRequest.phoneNumber || ''} placeholder="Số điện thoại" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <FormFloating label="Mã người bán" name="seller" value={searchRequest.seller || ''} placeholder="Mã người bán" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-2">
              <FormFloating type="number" label="Số kiện" name="numParcel" value={searchRequest.numParcel || ''} placeholder="Số kiến" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-3">
              <FormFloating type="text" label="Biển số xe" name="licensePlate" value={searchRequest.licensePlate || ''} placeholder="Biển số xe" onChange={onChangeInput}></FormFloating>
            </div>
            <div className="col-lg-3">
              <DateRangePicker ranges={searchRequest.ranges} label="Ngày nhập kho" placeholder="dd/mm/yyyy - dd/mm/yyyy" onChange={onChangeDateRange}></DateRangePicker>
            </div>
            <div className="col-lg-2">
              <FormFloatingSelect defaultValue={searchRequest.status} onChange={onChangeStatus} sources={orderStatusList} bindLabel="label" bindValue="value" label="Chọn trạng thái đơn hàng" placeholder="Trạng thái đơn hàng"></FormFloatingSelect>
            </div>
            <div className="col-lg-2 d-flex justify-content-start align-items-center">
              <button type="button" className="btn rounded-pill btn-icon btn-secondary btn-fab demo waves-effect waves-light me-2" onClick={onRefresh}>
                <span className="tf-icons ri-reset-right-line ri-22px"></span>
              </button>
              <button type="button" className="btn rounded-pill btn-icon btn-primary btn-fab demo waves-effect waves-light" onClick={onSearch}>
                <span className="tf-icons ri-search-line ri-22px"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Mã đơn hàng</th>
                  <th>Trạng thái</th>
                  <th>Mã KH</th>
                  <th>Mã người bán</th>
                  {/* <th>SĐT</th> */}
                  <th>Số kiện</th>
                  <th>Số cân</th>
                  <th>Số khối</th>
                  <th>Số cái</th>
                  <th>Ngày nhập kho</th>
                  <th>Thông tin xe</th>
                  <th>Ghi chú</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {orders.map((item, idx) => (
                  <tr key={idx} onClick={() => onViewDetail(item)}>
                    <td>{item.no}</td>
                    <td>
                      {item.orderId}{' '}
                      <span>
                        {item.warningYn && (
                          <span className="ms-2">
                            <i data-tooltip-id="my-tooltip" data-tooltip-content={item.warningMsg + '!'} className="fs-5 text-warning ri-error-warning-line"></i>
                            <Tooltip
                              id="my-tooltip"
                              style={{
                                backgroundColor: '#fff3dd',
                                color: '#fdb528',
                                zIndex: '9999',
                                fontStyle: 'italic'
                              }}
                            />
                          </span>
                        )}
                      </span>
                    </td>
                    <td>
                      <ViewOrderStatus orderDetail={item}></ViewOrderStatus>
                    </td>
                    <td>{item.status != CommonConstant.ORDER_STATUS_IMPORT ? (item.customerId ? item.customerId : '--') : '--'}</td>
                    <td>{item.status != CommonConstant.ORDER_STATUS_IMPORT ? (item.sellerId ? item.sellerId : '--') : '--'}</td>
                    {/* <td>{item.recipientPhoneNumber ? item.recipientPhoneNumber : '--'}</td> */}
                    <td>{item.numberParcel}</td>
                    <td>{item.weight ? item.weight : '--'}</td>
                    <td>{item.size ? item.size : '--'}</td>
                    <td>{item.piece ? item.piece : '--'}</td>
                    <td>{moment(item.whEntryDate).format('DD-MM-yyyy')}</td>
                    <td>
                      <ViewShipment shipments={item.shipments}></ViewShipment>
                    </td>

                    <td>{item.note}</td>
                  </tr>
                ))}
                {orders.length <= 0 && (
                  <tr>
                    <td colSpan={11}>Không có đơn hàng</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {totalRecords > PaginationConfig.PAGE_SIZE && (
        <div className="row mt-10">
          <div className="col-12 d-flex justify-content-center">
            <Pagination currentPage={searchRequest.page} totalRecords={totalRecords} size={PaginationConfig.PAGE_SIZE} onChange={(data) => onChangePagination(data)}></Pagination>
          </div>
        </div>
      )}

      <AppModel
        isShow={isOpenImportFileModal}
        onClose={() => {
          setIsOpenImportFileModal(false);
        }}
        title="Tạo đơn bằng file excel"
        size="lg"
      >
        <ImportFileModal onClose={(data) => onCloseImportFileModal(data)}></ImportFileModal>
      </AppModel>

      <AppModel
        isShow={isOpenExportFileModal}
        onClose={() => {
          setIsOpenExportFileModal(false);
        }}
        title="Xuất kho bằng file excel"
        size="lg"
      >
        <ExportFileModal onClose={(data) => onCloseExportFileModal(data)}></ExportFileModal>
      </AppModel>
    </>
  );
}
