import { useEffect, useState } from 'react';
import { DashboardService } from '../../core/services/dashboard.service';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../libs/hook';
import { userInfoSelector } from '../../libs/selectors/userSelector';
import NumberFormat from '../../components/common/NumberFormat';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userInfoSelector);
  // const [shipmentStatistics, setShipmentStatistics] = useState<any>({});
  const [inventoryStatistics, setInventoryStatistics] = useState<any>({});
  const [chartOptions, setChartOptions] = useState<any>();
  const [data, setData] = useState<any>({});
  const [year, setYear] = useState<any>(new Date().getFullYear());
  const [years, setYears] = useState<any[]>([]);
  const [currentOverView, setCurrentOverView] = useState<any>({});

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    dispatch(showSpinner());
    await Promise.all([getInventoryStatistics(), getCurrentOverview(), getRevenueStatistics(year), getData(year), getYears()]);
    setTimeout(() => {
      dispatch(hideSpinner());
    }, 500);
  };

  const getData = async (data: any) => {
    await DashboardService.getDashboardData(data).then((res) => {
      if (res.data.status) {
        let resTemp = res.data.responseData;
        let temp: any = {};
        temp.totalNumberParcelSucc = resTemp.totalNumberParcelSucc;
        temp.totalOrderSucc = resTemp.totalOrderSucc;
        temp.totalRevenue = resTemp.totalRevenue;
        // temp.totalVehicleCompletedCc = resTemp.vehicleStatistics.totalVehicleCompletedCc;
        // temp.totalVehicleWaitingCc = resTemp.vehicleStatistics.totalVehicleWaitingCc;

        setData({ ...temp });
      }
    });
  };

  const getYears = async () => {
    await DashboardService.getYears().then((res) => {
      if (res.data.status) {
        setYears(res.data.responseData);
      }
    });
  };

  const onChangeYear = async (item: any) => {
    if (item == year) {
      return;
    }
    dispatch(showSpinner());
    setYear(item);
    await Promise.all([getData(item), getRevenueStatistics(item)]);
    setTimeout(() => {
      dispatch(hideSpinner());
    }, 500);
  };

  // const getShipmentStatistics = async () => {
  //   await DashboardService.getShipmentStatistics().then((res) => {
  //     if (res.data.status) {
  //       setShipmentStatistics(res.data.responseData);
  //     }
  //   });
  // };

  const getCurrentOverview = async () => {
    await DashboardService.getCurrentOverview().then(res => {
      if (res.data.status) {
        setCurrentOverView(res.data.responseData);
      }
    })
  }

  const getInventoryStatistics = () => {
    DashboardService.getInventoryStatistics().then((res) => {
      if (res.data.status) {
        let inventoryStatisticsTemp = res.data.responseData?.statisticInventory;
        inventoryStatisticsTemp.inventoryNumberParcel = inventoryStatisticsTemp.totalNumberParcel - (inventoryStatisticsTemp.totalNumberParcelExported ? inventoryStatisticsTemp.totalNumberParcelExported : 0);
        inventoryStatisticsTemp.inventoryWeight = inventoryStatisticsTemp.totalWeight - (inventoryStatisticsTemp.totalWeightExported ? inventoryStatisticsTemp.totalWeightExported : 0);
        inventoryStatisticsTemp.inventorySize = inventoryStatisticsTemp.totalSize - (inventoryStatisticsTemp.totalSizeExported ? inventoryStatisticsTemp.totalSizeExported : 0);
        inventoryStatisticsTemp.totalInvOrder = res.data.responseData?.numberInventories;
        setInventoryStatistics({ ...inventoryStatisticsTemp });
      }
    });
  };

  const getRevenueStatistics = (param: any) => {
    DashboardService.getRevenueStatistics(param).then((res) => {
      let datas = res.data.responseData;
      if (res.data.status) {
        const series1 = datas.currentYearList?.map((item: any) => item.totalPayAmount);
        const series2 = datas.previousYearList?.map((item: any) => item.totalPayAmount);
        const chartOptionsTemp = {
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          accessibility: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          xAxis: {
            categories: ['Thg 1', 'Thg 2', 'Thg 3', 'Thg 4', 'Thg 5', 'Thg 6', 'Thg 7', 'Thg 8', 'Thg 9', 'Thg 10', 'Thg 11', 'Thg 12'],
            crosshair: true,
            accessibility: {
              description: 'Month'
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            }
          },
          tooltip: {
            valueSuffix: ' (VND)'
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: [
            {
              name: 'Doanh thu năm ' + param,
              data: series1
            },
            {
              name: 'Doanh thu năm ' + (param - 1),
              data: series2
            }

          ]
        };
        setChartOptions(chartOptionsTemp);
      }
    });
  };

  const handleRound = (number: any) => {
    const rounded = parseFloat(number.toFixed(2));
    return rounded;
  };

  return (
    <>
      <div className="row g-6">
        <div className="col-lg-7 col-sm-12">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2">Thống kê tồn kho</h5>
              </div>
            </div>
            <div className="card-body">
              <div className="row g-6">
                <div className="col-lg-6 col-sm-12">
                  <div className="card-body dsb-border">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="avatar me-4">
                        <div className="avatar-initial color-order-import rounded-3">
                          <i className="ri-file-list-2-line ri-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 me-2">
                            <NumberFormat value={inventoryStatistics.totalInvOrder ? inventoryStatistics.totalInvOrder : 0}></NumberFormat>
                          </h5>
                        </div>
                        <p className="mb-0">Tổng đơn hàng tồn kho</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="card-body dsb-border">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="avatar me-4">
                        <div className="avatar-initial color-order-import rounded-3">
                          <i className="ri-archive-drawer-line ri-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 me-2">
                            <NumberFormat value={inventoryStatistics.inventoryNumberParcel >= 0 ? inventoryStatistics.inventoryNumberParcel : 0}></NumberFormat>
                            <span className="fs-6 fw-normal"> kiện</span>
                          </h5>
                        </div>
                        <p className="mb-0">Số kiện tồn kho</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="card-body dsb-border">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="avatar me-4">
                        <div className="avatar-initial color-order-import rounded-3">
                          <i className="ri-weight-line ri-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 me-2">
                            <NumberFormat value={handleRound(inventoryStatistics.inventoryWeight >= 0 ? inventoryStatistics.inventoryWeight : 0)}></NumberFormat>
                            <span className="fs-6 fw-normal"> kg</span>
                          </h5>
                        </div>
                        <p className="mb-0">Số cân tồn kho</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="card-body dsb-border">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="avatar me-4">
                        <div className="avatar-initial color-order-import rounded-3">
                          <i className="ri-instance-line ri-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 me-2">
                            <NumberFormat value={handleRound(inventoryStatistics.inventorySize >= 0 ? inventoryStatistics.inventorySize : 0)}></NumberFormat>
                            <span className="fs-6 fw-normal">
                              {' '}
                              m<sup>3</sup>
                            </span>
                          </h5>
                        </div>
                        <p className="mb-0">Số khối tồn kho</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12">
          <div className="card h-100">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2">Tình hình hiện tại</h5>
              </div>
            </div>
            <div className="card-body pb-2">
              <div className="table-responsive">
                <table className="table card-table">
                  <tbody className="table-border-bottom-0">
                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-truck-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">Số xe chờ thông quan</h6>
                        </div>
                      </td>
                      <td className="text-start pe-0 text-nowrap text-end">
                        <h6 className="mb-0">
                          <NumberFormat value={currentOverView.totalVehicleWaitingCc ? currentOverView.totalVehicleWaitingCc : 0}></NumberFormat>
                        </h6>
                      </td>
                    </tr>

                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-archive-drawer-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">Số kiện chờ thông quan</h6>
                        </div>
                      </td>
                      <td className="text-start pe-0 text-nowrap text-end">
                        <h6 className="mb-0">
                          <NumberFormat value={currentOverView.totalNumberParcelExported ? currentOverView.totalNumberParcelExported : 0}></NumberFormat>
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-truck-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">Số xe đã thông quan</h6>
                        </div>
                      </td>
                      <td className="text-start pe-0 text-nowrap text-end">
                        <h6 className="mb-0">
                        <NumberFormat value={currentOverView.totalVehicleCompletedCc ? currentOverView.totalVehicleCompletedCc : 0}></NumberFormat>
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-75 ps-0">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="me-2">
                            <i className="text-heading ri-file-list-2-line ri-24px"></i>
                          </div>
                          <h6 className="mb-0 fw-normal">Số đơn đang giao</h6>
                        </div>
                      </td>
                      <td className="text-start pe-0 text-nowrap text-end">
                        <h6 className="mb-0">
                        <NumberFormat value={currentOverView.totalOrderSucc ? currentOverView.totalOrderSucc : 0}></NumberFormat>
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between mb-6 text-center text-sm-start gap-2 mt-6">
        <div className="mb-2 mb-sm-0">
          <h5 className="mb-1">Thống kê năm {year}</h5>
        </div>
        <div className="btn-group">
          <button type="button" className="btn btn-outline-primary" data-bs-toggle="dropdown">
            {year}
          </button>
          <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu">
            {years.map((item: any, idx: any) => (
              <li key={idx}>
                <a className="dropdown-item" onClick={() => onChangeYear(item)}>
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="row g-6">
        <div className="col-lg-4 col-sm-12">
          <div className="card h-100">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2">Tổng quan</h5>
              </div>
            </div>
            <div className="card-body">
              <div className="row g-6">
                <div className="col-lg-12 col-sm-12">
                  <div className="card-body dsb-border">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="avatar me-4">
                        <div className="avatar-initial color-order-complete rounded-3">
                          <i className="ri-file-list-2-line ri-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 me-2">
                            <NumberFormat value={data.totalOrderSucc}></NumberFormat>
                          </h5>
                        </div>
                        <p className="mb-0">Tổng đơn hàng giao thành công</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="card-body dsb-border">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="avatar me-4">
                        <div className="avatar-initial color-order-complete rounded-3">
                          <i className="ri-archive-drawer-line ri-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 me-2">
                            <NumberFormat value={data.totalNumberParcelSucc}></NumberFormat>
                            <span className="fs-6 fw-normal"> kiện</span>
                          </h5>
                        </div>
                        <p className="mb-0">Số kiện giao thành công</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="card-body dsb-border">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="avatar me-4">
                        <div className="avatar-initial color-order-complete rounded-3">
                          <i className="ri-wallet-3-line ri-24px"></i>
                        </div>
                      </div>
                      <div className="card-info">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 me-2">
                            <NumberFormat value={data.totalRevenue ? data.totalRevenue : 0}></NumberFormat>
                            <span className="fs-6 fw-normal"> VND</span>
                          </h5>
                        </div>
                        <p className="mb-0">Tổng doanh thu</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-sm-12">
          <div className="card h-100">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="card-title mb-0">
                <h5 className="m-0 me-2 mb-1">Biểu đồ thống kê doanh thu năm {year}</h5>
              </div>
              {/* <div className="btn-group">
                <button type="button" className="btn btn-outline-primary" data-bs-toggle="dropdown">
                  {revenueYear}
                </button>
                <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  {years.map((item: any, idx: any) => (
                    <li key={idx}>
                      <a className="dropdown-item" onClick={() => onChangeYearRevenueStatistics(item)}>
                        {item}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
            <div className="card-body">
              <div id="shipmentStatisticsChart">{chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
