import './App.css';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "flatpickr/dist/themes/light.css";

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { indexRouter } from './routers/indexRouter';
import { ToastContainer } from 'react-toastify';
import { useAppSelector } from './libs/hook';
import { spinnerLoadingSelector } from './libs/selectors/spinnerSelector';
import LoadingView from './components/ui/LoadingView';

const router = createBrowserRouter(indexRouter);

function App() {
  const loading = useAppSelector(spinnerLoadingSelector);

  return (
    <React.Fragment>
      {loading && <LoadingView />}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        // transition="Bounce"
      />
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default App;
