import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShipmentService } from '../../core/services/shipment.service';
import { useAppDispatch, useAppSelector } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { ImageInspectionService } from '../../core/services/image-inspection.service';
import AppModel from '../../components/ui/AppModel';
import UploadFileModal from './components/UploadFileModal';
import { RoleConstant } from '../../core/constants/role.constant';
import { userInfoSelector } from '../../libs/selectors/userSelector';

export default function VehicleDetail() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useAppSelector(userInfoSelector);
  const [vehicleDetail, setVehicleDetail] = useState<any>({});
  const [isOpenUploadModal, setIsOpenUploadModal] = useState<any>(false);
  const [imageInspections, setImageInspections] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      getVehicleDetail();
      getImageInspections();
    }
  }, [id]);

  const getVehicleDetail = () => {
    dispatch(showSpinner());
    ShipmentService.getVehicleDetailByLicensePlate(id)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          setVehicleDetail(res.data.responseData);
        }
      });
  };

  const getImageInspections = () => {
    ImageInspectionService.getByLicensePlate(id).then((res) => {
      if (res.data.status) {
        setImageInspections(res.data.responseData);
      }
    });
  };

  const onConfirmCC = () => {
    if (vehicleDetail.status != '09-01') {
      return;
    }
    Swal.fire({
      title: 'Xác nhận thông quan?',
      text: 'Bạn không thể hoàn tác lại được!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Xác nhận!',
      cancelButtonText: 'Hủy'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showSpinner());
        ShipmentService.ccShipmentByLicensePlate(id)
          .finally(() => {
            setTimeout(() => {
              dispatch(hideSpinner());
            }, 500);
          })
          .then((res) => {
            if (res.data.status) {
              toast.success('Thông quan thành công');
              getVehicleDetail();
            } else {
              toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
            }
          });
      }
    });
  };

  const onOpenUploadModal = () => {
    setIsOpenUploadModal(true);
  };

  const onCloseUploadModal = (event: any) => {
    setIsOpenUploadModal(false);
    if (event) {
      dispatch(showSpinner());
      ImageInspectionService.getByLicensePlate(id)
        .finally(() => {
          setTimeout(() => {
            dispatch(hideSpinner());
          }, 500);
        })
        .then((res) => {
          if (res.data.status) {
            setImageInspections(res.data.responseData);
          }
        });
    }
  };

  const onBack = () => {
    navigate('/vehicles', { state: location.state });
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 gap-6">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex align-items-center mb-1">
            <h5 className="mb-0">
              Biển số xe #<span className="text-primary">{id}</span>
            </h5>
          </div>
        </div>

        <div className="d-flex align-content-center flex-wrap gap-2">
          <button className="btn btn-cancel" onClick={onBack}>
            Quay lại
          </button>
          {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHVN)) && (
            <>
              {vehicleDetail.status == '09-01' && (
                <button className="btn btn-order-cc" type="button" onClick={onConfirmCC}>
                  Thông quan
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card mb-6">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title m-0">Thông tin xe</h5>
            </div>
            <div className="card-body ">
              <div className="">
                {' '}
                <span>
                  <i className="ri-calendar-line"></i>
                </span>{' '}
                Ngày xuất kho: {moment(vehicleDetail.exportedDate).format('DD-MM-yyyy hh:mm')}
              </div>
              <div className="mt-3">
                {' '}
                <span>
                  <i className="ri-car-line"></i>
                </span>{' '}
                Loại xe: {vehicleDetail.vehicleType}
              </div>
              <div className="mt-3">
                {' '}
                <span>
                  <i className="ri-ancient-gate-line"></i>
                </span>{' '}
                Cửa khẩu: {vehicleDetail.borderGate}
              </div>
              <div className="mt-3">
                {' '}
                <span>
                  <i className="ri-map-pin-line"></i>
                </span>{' '}
                Điểm đến: {vehicleDetail.address}
              </div>
              {vehicleDetail.ccDate && (
                <div className="mt-3">
                  {' '}
                  <span>
                    <i className="ri-calendar-line"></i>
                  </span>{' '}
                  Ngày thông quan: {moment(vehicleDetail.ccDate).format('DD-MM-yyyy hh:mm')}
                </div>
              )}
            </div>
          </div>
          <div className="card mb-6">
            <div className="card-header">
              <h5 className="card-title m-0">Danh sách đơn hàng trong chuyến</h5>
            </div>
            <div className="card-body mt-3">
              <div className="table-responsive text-nowrap">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Mã đơn hàng</th>
                      <th>Biến số xe</th>
                      <th>Loại hàng hóa</th>
                      <th>Số kiện</th>
                      <th>Số cân</th>
                      <th>Số khối</th>
                      <th>Số cái</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {vehicleDetail.shipments?.map((item: any, idx: any) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.orderId}</td>
                        <td>{item.licensePlate}</td>
                        <td>{item.productType}</td>
                        <td>{item.numberParcel}</td>
                        <td>{item.weight ? item.weight : '--'}</td>
                        <td>{item.size ? item.size : '--'}</td>
                        <td>{item.piece ? item.piece : '--'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {vehicleDetail.status == '09-02' && (
            <div className="card mb-6">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="card-title m-0">Hình ảnh kiểm hàng</h5>
                {(currentUser.roleIds?.includes(RoleConstant.ROLE_ADMIN) || currentUser.roleIds?.includes(RoleConstant.ROLE_WHVN)) && (
                  <button className="btn btn-primary" type="button" onClick={onOpenUploadModal}>
                    Tải ảnh
                  </button>
                )}
              </div>
              <div className="card-body">
                {imageInspections.length > 0 && (
                  <div className="dropzone needsclick dz-clickable">
                    {imageInspections?.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
                          <div className="dz-details">
                            <div className="dz-thumbnail">
                              <img src={`${process.env.REACT_APP_API_URL}/public/imageView/${item.tccoFile.atchFleSeq}`} />
                            </div>
                          </div>
                          <div style={{ padding: '.625rem' }}>{item.tccoFile.fleNm}</div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {imageInspections.length <= 0 && <div>Chưa có ảnh kiểm hàng</div>}
              </div>
            </div>
          )}
        </div>
      </div>

      <AppModel
        isShow={isOpenUploadModal}
        onClose={() => {
          setIsOpenUploadModal(false);
        }}
        title="Thêm hình ảnh kiểm hàng"
        size="lg"
      >
        <UploadFileModal onClose={(data) => onCloseUploadModal(data)} licensePlate={id}></UploadFileModal>
      </AppModel>
    </>
  );
}
