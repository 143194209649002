import axios from 'axios';

export const ImageInspectionService = {
  uploadImageInspection(licensePlate: any, files: any) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files?.length; i++) {
      formData.append('multiparts', files[i]);
    }
    formData.append('licensePlate', licensePlate);
    const url = process.env.REACT_APP_API_URL + '/image-inspection/upload-image-inspection';
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multi-part/formdata'
      }
    });
  },

  getByLicensePlate(licensePlate: any) {
    const url = process.env.REACT_APP_API_URL + '/image-inspection/get-by-licensePlate?licensePlate=' + licensePlate;
    return axios.get(url);
  }
};
