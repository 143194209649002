import { forwardRef, useEffect, useId, useState } from 'react';

type FormFloatingNumberProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  className?: string;
  isError?: boolean;
  errMsg?: any;
  register?: any;
  defaultValue?: any;
  required?: boolean | undefined;
  mode?: 'decimal' | 'currency' | undefined;
  locale?: 'VND' | 'RMB' | undefined;
};

const FormFloatingNumber = forwardRef<HTMLInputElement, FormFloatingNumberProps>(({ required, register, defaultValue, errMsg, isError, label, className, ...restProps }, ref) => {
  const inputId = useId();

  const [value, setValue] = useState('');

  useEffect(() => {
    const formattedPrice = Intl.NumberFormat('vi-VN').format(defaultValue);
    setValue(formattedPrice);
  }, [defaultValue]);

  const handleChange = (e: any) => {
    let inputValue: any = e.target.value.replace(/\./g, '');

    if (inputValue.startsWith('0') && inputValue.length > 1) {
      inputValue = inputValue.slice(1);
    }

    if (/^[0-9]*$/.test(inputValue)) {
      if (inputValue.length > 0) {
        const formattedPrice = Intl.NumberFormat('vi-VN').format(inputValue);
        setValue(formattedPrice);
      } else {
        setValue(inputValue);
      }

      e.target.value = inputValue;
      restProps.onChange?.(e);
    }
  };

  return (
    <>
      {restProps.mode == 'currency' && (
        <div className={'input-group input-group-merge ' + (className ? className : '')}>
          {restProps.locale == 'RMB' && (
            <>
              <span className={'input-group-text' + (restProps.disabled ? ' disabled' : '')}>¥</span>
              <div className="form-floating form-floating-outline">
                <input type="text" {...restProps} ref={ref} className={'form-control' + (isError ? ' is-invalid' : '')} id={inputId} onChange={handleChange} value={value} {...(register && register())} />
                <label htmlFor={inputId} style={{ marginLeft: '-25px' }}>
                  {label}
                  {required && <span className="error-feedback"> *</span>}
                </label>
                {isError && <div className="error-feedback"> {errMsg} </div>}
              </div>
            </>
          )}

          {restProps.locale != 'RMB' && (
            <>
              <div className="form-floating form-floating-outline">
                <input type="text" {...restProps} ref={ref} className={'form-control' + (isError ? ' is-invalid' : '')} id={inputId} onChange={handleChange} value={value} {...(register && register())} />
                <label htmlFor={inputId}>
                  {label}
                  {required && <span className="error-feedback"> *</span>}
                </label>
                {isError && <div className="error-feedback"> {errMsg} </div>}
              </div>
              <span className={'input-group-text cursor-pointer' + (restProps.disabled ? ' disabled' : '')}>đ</span>
            </>
          )}
        </div>
      )}

      {restProps.mode != 'currency' && (
        <div className={'form-floating form-floating-outline ' + (className ? className : '')}>
          <input type="text" {...restProps} ref={ref} className={'form-control' + (isError ? ' is-invalid' : '')} id={inputId} onChange={handleChange} value={value} {...(register && register())} />
          <label htmlFor={inputId}>
            {label}
            {required && <span className="error-feedback"> *</span>}
          </label>
          {isError && <div className="error-feedback"> {errMsg} </div>}
        </div>
      )}
    </>
  );
});
export default FormFloatingNumber;
