import { Navigate } from "react-router-dom";
import { layoutRouter } from "./layoutRouter";
import Login from "../pages/auth/login/Login";
import Error403 from "../pages/errors/Error403";
import Error404 from "../pages/errors/Error404";
import Error500 from "../pages/errors/Error500";

export const indexRouter: any = [
  { path: "/", element: <Navigate to="/dashboard" replace /> },
  { path: "login", element: <Login /> },
  layoutRouter,
  { path: "not-permission", element: <Error403/>},
  { path: "*", element: <Error404/>},
  { path: "under-maintenance", element: <Error500/>},
];
