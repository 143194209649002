import FormFloating from '../../components/ui/form/FormFloating';
import FormFloatingTextArea from '../../components/ui/form/FormFloatingTextArea';
import FormFloatingSelect from '../../components/ui/form/FormFloatingSelect';
import { UserService } from '../../core/services/user.service';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { OrderService } from '../../core/services/order.service';
import { useNavigate, useParams } from 'react-router-dom';
import UploadFile from '../../components/ui/UploadFile';
import { FileService } from '../../core/services/common/file.service';
import { toast } from 'react-toastify';

import FormFloatingNumber from '../../components/ui/form/FormFloatingNumber';
import NumberFormat from '../../components/common/NumberFormat';
import { CommonConstant } from '../../core/constants/common.constant';

export default function EditOrder() {
  const { orderId }: any = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sellers, setSellers] = useState<any[]>([]);
  const [filesToAdd, setFilesToAdd] = useState<any>([]);
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [filesToDelete, setFilesToDelete] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [trackingImport, setTrackingImport] = useState<any>({});
  const [orderRequest, setOrderRequest] = useState<any>({});
  const [timeoutCustomerId, setTimeoutCustomerId] = useState<any>();
  const [timeoutPhoneNumber, setTimeoutPhoneNumber] = useState<any>();
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, [orderId]);

  const getOrder = () => {
    dispatch(showSpinner());
    OrderService.getOrderById(orderId)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setOrderDetail(res.data.responseData);
        setFormValue(res.data.responseData);
        setTrackingImport(res.data.responseData?.trackingImages);
      });
  };

  const setFormValue = (orderDetail: any) => {
    setImages(orderDetail.trackings[0].trackingImages);

    orderRequest.orderId = orderDetail.orderId;
    orderRequest.customerId = orderDetail.customerId;
    orderRequest.sellerId = orderDetail.sellerId;
    orderRequest.recipientPhoneNumber = orderDetail.recipientPhoneNumber;
    orderRequest.note = orderDetail.note;
    orderRequest.productType = orderDetail.productType;
    orderRequest.numberParcel = orderDetail.numberParcel;
    orderRequest.size = orderDetail.size;
    orderRequest.weight = orderDetail.weight;
    orderRequest.piece = orderDetail.piece;

    orderRequest.shippingFee = orderDetail.shippingFee;
    orderRequest.domesticFee = orderDetail.domesticFee;
    orderRequest.towingFee = orderDetail.towingFee;
    orderRequest.forkliftFee = orderDetail.forkliftFee;
    orderRequest.woodenFee = orderDetail.woodenFee;
    orderRequest.payOnHehalf = orderDetail.payOnHehalf;
    orderRequest.totalFee = orderDetail.totalFee;
    orderRequest.payAmount = orderDetail.payAmount;

    setOrderRequest({ ...orderRequest });
  };

  useEffect(() => {
    dispatch(showSpinner());
    initData();
    setTimeout(() => {
      dispatch(hideSpinner());
    }, 500);
  }, []);

  const initData = async () => {
    await Promise.all([getSellers()]);
  };

  const getSellers = async () => {
    await UserService.getAllSeller().then((res) => {
      setSellers(res.data);
    });
  };

  const onCancel = () => {
    navigate(`/order-mgt/detail/${orderId}`);
  };

  const onSubmit = async () => {
    setSubmitted(true);
    if (validate()) {
      toast.error('Nhập thiếu các trường bắt buộc');
      return;
    }
    // if (orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT && (!orderRequest.customerId || !orderRequest.recipientPhoneNumber || !orderRequest.sellerId)) {
    //   return;
    // }
    if (filesToDelete?.length == trackingImport?.trackingImages?.length && filesToAdd.length <= 0) {
      toast.error('Hình ảnh còn thiếu');
      return;
    }

    dispatch(showSpinner());
    if (filesToAdd.length > 0) {
      FileService.multiFileUpload(filesToAdd).then(
        (res) => {
          if (!res.data.status) {
            toast.error('Đã xảy ra lỗi trong quá trình upload file, vui lòng thử lại');
            return;
          }
          orderRequest.filesToAdd = res.data.responseData;
          handleUpdateOrder(orderRequest);
        },
        (error) => {
          dispatch(hideSpinner());
        }
      );
    } else {
      handleUpdateOrder(orderRequest);
    }
  };

  const validate = () => {
    if (!orderRequest.orderId || orderRequest.orderId?.trim().length <= 0 || !orderRequest.productType || orderRequest.productType?.trim().length <= 0 || !orderRequest.numberParcel) {
      return true;
    }

    return false;
  }

  const handleUpdateOrder = (data: any) => {
    data.orderId = orderDetail.orderId;
    data.trackId = orderDetail.trackings[0].trackId;
    data.filesToDelete = filesToDelete;
    OrderService.editOrder(data)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          navigate(`/order-mgt/detail/${orderId}`);
        }
      });
  };

  const onChangeSeller = (event: any) => {
    orderRequest.sellerId = event?.username;
    setOrderRequest({ ...orderRequest });
  };

  const onChangeFile = (event: any) => {
    setFilesToAdd(event.filesToAdd);
    setFilesToDelete(event.filesToDelete);
  };

  const onChangeValue = (event: any) => {
    let valueTemp = event.target.value + '';
    // setValue(event.target.name, valueTemp.trim());
    orderRequest[event.target.name] = valueTemp.trimStart();
    const sum = Number(orderRequest.domesticFee || 0) + Number(orderRequest.towingFee || 0) + Number(orderRequest.forkliftFee || 0) + Number(orderRequest.shippingFee || 0) + Number(orderRequest.woodenFee || 0) + Number(orderRequest.payOnHehalf || 0);
    orderRequest.totalFee = sum;

    setOrderRequest({ ...orderRequest });
  };

  const onChangeCustomerId = (event: any) => {
    orderRequest.customerId = event.target.value;
    setOrderRequest({ ...orderRequest });
    fillCustomerPhone(event.target.value);
  };

  const onChangePhoneNumber = (event: any) => {
    orderRequest.recipientPhoneNumber = event.target.value;
    setOrderRequest({ ...orderRequest });
    fillCustomerId(event.target.value);
  };

  const fillCustomerId = (customerPhone: any) => {
    if (timeoutPhoneNumber) {
      clearTimeout(timeoutPhoneNumber);
    }
    const timeoutTemp = setTimeout(() => {
      UserService.getCustomerByPhoneNumber(customerPhone).then((res) => {
        if (res.data.status) {
          let userTemp = res.data.responseData;
          orderRequest.customerId = userTemp.username;
          setOrderRequest({ ...orderRequest });
        } else {
          UserService.getCustomerByUserName(orderRequest.customerId).then((res) => {
            if (res.data.status) {
              orderRequest.customerId = '';
              setOrderRequest({ ...orderRequest });
            }
          });
        }
      });
    }, 500);
    setTimeoutPhoneNumber(timeoutTemp);
  };

  const fillCustomerPhone = (customerIdP: any) => {
    if (timeoutCustomerId) {
      clearTimeout(timeoutCustomerId);
    }
    const timeoutTemp = setTimeout(() => {
      UserService.getCustomerByUserName(customerIdP).then((res) => {
        if (res.data.status) {
          let userTemp = res.data.responseData;
          orderRequest.recipientPhoneNumber = userTemp.phoneNumber;
          setOrderRequest({ ...orderRequest });
        } else {
          UserService.getCustomerByPhoneNumber(orderRequest.recipientPhoneNumber).then((res) => {
            if (res.data.status) {
              orderRequest.recipientPhoneNumber = '';
              setOrderRequest({ ...orderRequest });
            }
          });
        }
      });
    }, 500);
    setTimeoutCustomerId(timeoutTemp);
  };

  return (
    <>
      <div className="app-ecommerce">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="mb-1">Cập nhật thông tin đơn hàng</h4>
            {/* <p className="mb-0">Orders placed across your store</p> */}
          </div>
          <div className="d-flex align-content-center flex-wrap gap-4">
            <button type="button" className="btn btn-outline-secondary" onClick={onCancel}>
              Hủy
            </button>
            <button type="button" className="btn btn-primary" onClick={onSubmit}>
              Cập nhật
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-7">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin chung</h5>
              </div>
              <div className="card-body">
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating type="text" defaultValue={orderId || ''} label="Mã đơn hàng" placeholder="Mã đơn hàng" disabled></FormFloating>
                  </div>
                </div>
                {/* {orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT && ( */}
                  <>
                    <div className="row mb-5 gx-5">
                      <div className="col">
                        <FormFloating
                          type="text"
                          label="Mã khách hàng"
                          placeholder="Mã khách hàng"
                          isError={submitted && !orderRequest.customerId ? true : false}
                          errMsg="Mã khách hàng còn trống"
                          onChange={onChangeCustomerId}
                          maxLength={10}
                          value={orderRequest.customerId || ''}
                          name="customerId"
                          required
                        ></FormFloating>
                      </div>
                      <div className="col">
                        <FormFloating
                          type="number"
                          label="SĐT người nhận"
                          placeholder="SĐT người nhận"
                          maxLength={10}
                          onChange={onChangePhoneNumber}
                          name="recipientPhoneNumber"
                          value={orderRequest.recipientPhoneNumber || ''}
                          isError={submitted && !orderRequest.recipientPhoneNumber ? true : false}
                          errMsg="Số điện thoại còn trống"
                          required
                        ></FormFloating>
                      </div>
                    </div>

                    <div className="row mb-5 gx-5">
                      <div className="col">
                        <FormFloatingSelect
                          label="Nhân viên bán hàng"
                          sources={sellers}
                          bindLabel="username"
                          bindValue="username"
                          onChange={(event) => onChangeSeller(event)}
                          isError={submitted && !orderRequest.sellerId ? true : false}
                          errMsg="Nhân viên bán hàng còn trống"
                          defaultValue={orderDetail.sellerId}
                          required
                        ></FormFloatingSelect>
                      </div>
                    </div>
                  </>
                {/* )} */}

                <FormFloatingTextArea className="mb-5" label="Chú thích" placeholder="Chú thích" onChange={onChangeValue} name="note" value={orderRequest.note || ''} />
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0 card-title">Hình ảnh đơn hàng</h5>
              </div>
              <div className="card-body">
                <UploadFile files={images} onChange={onChangeFile} multiple accept="image/*"></UploadFile>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-5">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-title mb-0">Thông tin hàng hóa</h5>
              </div>
              <div className="card-body">
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      label="Loại hàng hóa"
                      placeholder="Loại hàng hóa"
                      onChange={onChangeValue}
                      name="productType"
                      isError={submitted && !orderRequest.productType ? true : false}
                      errMsg="Loại hàng hóa không được trống"
                      value={orderRequest.productType || ''}
                      required
                    ></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      type="number"
                      label="Số kiện"
                      placeholder="Số kiện"
                      onChange={onChangeValue}
                      name="numberParcel"
                      isError={submitted && !orderRequest.numberParcel ? true : false}
                      errMsg="Số kiện không được trống"
                      value={orderRequest.numberParcel || 0}
                      required
                    ></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating label={'Số khối (m\u00B3)'} placeholder={'Số khối (m\u00B3)'} onChange={onChangeValue} name="size" type="number" value={orderRequest.size || 0}></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating label="Số cân (kg)" placeholder="Số cân (kg)" onChange={onChangeValue} name="weight" type="number" value={orderRequest.weight || 0}></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating type="number" label="Số cái" placeholder="Số cái" onChange={onChangeValue} name="piece" value={orderRequest.piece || 0}></FormFloating>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin cước phí</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* <div className="col-sm-12 py-3">
                    <FormFloatingNumber
                      label="Cước vận chuyển"
                      placeholder="Cước vận chuyển"
                      name="shippingFee"
                      onChange={onChangeValue}
                      defaultValue={orderRequest.shippingFee || 0}
                      min="0"
                      disabled={orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT ? true : false}
                      mode="currency" 
                      locale="RMB"
                    />
                  </div> */}
                  <div className="col-12 py-3 border-bottom">
                    <div>Cước phụ</div>
                    <div className="ps-10 mt-2">
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Cước nội địa"
                            placeholder="Cước nội địa"
                            name="domesticFee"
                            onChange={onChangeValue}
                            defaultValue={orderRequest.domesticFee || 0}
                            min="0"
                            disabled={orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT ? true : false}
                            mode="currency" 
                            locale="RMB"
                          />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Kéo xe"
                            placeholder="Kéo xe"
                            name="towingFee"
                            onChange={onChangeValue}
                            defaultValue={orderRequest.towingFee || 0}
                            min="0"
                            disabled={orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT ? true : false}
                            mode="currency" 
                            locale="RMB"
                          />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Xe nâng"
                            placeholder="Xe nâng"
                            name="forkliftFee"
                            onChange={onChangeValue}
                            defaultValue={orderRequest.forkliftFee || 0}
                            min="0"
                            disabled={orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT ? true : false}
                            mode="currency" 
                            locale="RMB"
                          />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Đóng gỗ"
                            placeholder="Đóng gỗ"
                            name="woodenFee"
                            onChange={onChangeValue}
                            defaultValue={orderRequest.woodenFee || 0}
                            min="0"
                            disabled={orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT ? true : false}
                            mode="currency" 
                            locale="RMB"
                          />
                        </div>

                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber
                            label="Thanh toán hộ"
                            placeholder="Thanh toán hộ"
                            name="payOnHehalf"
                            onChange={onChangeValue}
                            defaultValue={orderRequest.payOnHehalf || 0}
                            min="0"
                            disabled={orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT ? true : false}
                            mode="currency" 
                            locale="RMB"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 d-flex justify-content-between">
                    <div className="fs-5 fw-semibold">Tổng tiền</div>
                    {orderRequest.totalFee ? (
                      <div className="text-danger fw-semibold">
                        {' '}
                        ¥ <NumberFormat value={orderRequest.totalFee}></NumberFormat>
                      </div>
                    ) : (
                      <div className="text-danger fw-semibold">--</div>
                    )}
                  </div>
                  <div className="col-12 py-6">
                    <FormFloatingNumber
                      label="Số tiền cần thanh toán"
                      placeholder="Số tiền cần thanh toán"
                      name="payAmount"
                      onChange={onChangeValue}
                      defaultValue={orderRequest.payAmount || 0}
                      min="0"
                      disabled={orderDetail.status != CommonConstant.ORDER_STATUS_IMPORT ? true : false}
                      mode="currency" 
                      locale="RMB"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
