import { useNavigate } from 'react-router-dom';

export default function Error403() {
  const navigate = useNavigate();
  return (
    <div className="misc-wrapper">
      <h1 className="mb-2 mx-2" style={{ fontSize: '6rem', lineHeight: '6rem' }}>
        403
      </h1>
      <h4 className="mb-2">Bạn không được ủy quyền! 🔐</h4>
      <p className="mb-3 mx-2">Bạn không có quyền truy cập trang này, vui lòng quay lại trang chủ!</p>
      <div className="d-flex justify-content-center mt-12">
        <img src="/assets/img/illustrations/misc-not-authorized-object.png" alt="misc-not-authorized" className="img-fluid misc-object d-none d-lg-inline-block" width="190" />
        <img src="/assets/img/illustrations/misc-bg-light.png" alt="misc-not-authorized" className="misc-bg d-none d-lg-inline-block" />
        <div className="d-flex flex-column align-items-center z-3">
          <img src="/assets/img/illustrations/misc-not-authorized-illustration.png" alt="misc-not-authorized" className="img-fluid z-1" width="160" />
          <div>
            <a onClick={() => navigate('/order-mgt')} className="btn btn-primary cursor-pointer text-center my-10 waves-effect waves-light text-white">
              Quay lại trang chủ
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
