type ViewShipmentProps = {
  shipments: any[];
};
export default function ViewShipment(props: ViewShipmentProps) {

  return <>
    {
        props.shipments?.map((item: any, idx: any) => (
            <div key={idx}>{item.licensePlate + ' ' + (item.vehicleType ? item.vehicleType : ' -- ') + ' ' + (item.borderGate ? item.borderGate : ' -- ') + ' ' + (item.address ? item.address : ' -- ')}</div>
        ))
    }
  </>;
}
