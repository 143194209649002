import { useEffect, useState } from 'react';
import { PaginationConfig } from '../../../core/config/PaginationConfig';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { NotificationService } from '../../../core/services/notification.service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function NotificationsTab() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [searchRequest, setSearchRequest] = useState<any>({});
  const [totalNoti, setTotalNoti] = useState<any>(0);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    searchRequest.page = 0;
    searchRequest.size = PaginationConfig.PAGE_SIZE;
    setSearchRequest({ ...searchRequest });
    getNotifications(searchRequest);
  };

  const getNotifications = (params: any) => {
    dispatch(showSpinner());
    NotificationService.getNotifications(params)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setNotifications([...notifications, ...res.data]);
        setTotalNoti(res.headers['x-total-count']);
      });
  };

  const onNavigate = (item: any) => {
    markAsRead(item.id);
    navigate(`/order-mgt/detail/${item.orderId}`);
  };

  const markAsRead = (id: any) => {
    NotificationService.markAsReadById(id).then((res) => {
      init();
    });
  };

  const onDeleteNoti = (event: any, id: any) => {
    event.stopPropagation();
    NotificationService.deleteById(id).then((res) => {
      init();
    });
  };

  const loadMoreNoti = () => {
    searchRequest.page = searchRequest.page + 1;
    setSearchRequest({ ...searchRequest });
    getNotifications(searchRequest);
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Thông báo</h5>
          <div className="">
            <ul className="list-group list-group-flush">
              {notifications.map((item, idx) => (
                <li key={idx} className="list-group-item list-group-item-action dropdown-notifications-item" onClick={() => onNavigate(item)}>
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar">
                        <span className="avatar-initial rounded-circle bg-label-success">
                          <i className="ri-truck-line"></i>
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1 small">{item.title}</h6>
                      <small className="mb-1 d-block text-body">{item.content}</small>
                      <small className="text-muted">{moment(item.createdDate).format('DD-MM-yyyy hh:mm')}</small>
                    </div>
                    <div className="flex-shrink-0 dropdown-notifications-actions">
                      {!item.readYn && (
                        <a className="dropdown-notifications-read">
                          <span className="badge badge-dot bg-danger"></span>
                        </a>
                      )}

                      <a className="dropdown-notifications-archive ms-3" onClick={(event) => onDeleteNoti(event, item.id)}>
                        <span className="ri-close-line ri-20px"></span>
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {notifications.length < totalNoti && (
            <div className="mt-5 text-center">
              <button type="button" className="px-10 btn rounded-pill btn-primary waves-effect waves-light" onClick={loadMoreNoti}>
                Xem thêm
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
