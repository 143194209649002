import { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
type SliderImageProps = {
  atchFleSeqs: any[];
  onClose: () => void;
};
export default function SliderImage(props: SliderImageProps) {
  useEffect(() => {
    const element: any = document.getElementById('slider-image-overlay-id');
    element?.classList.remove('d-none');

    const bodyElement: any = document.getElementById('tl-body-id');
    bodyElement?.classList.add('overflow-hidden');

    return () => {
      const element: any = document.getElementById('slider-image-overlay-id');
      element?.classList.add('d-none');

      const bodyElement: any = document.getElementById('tl-body-id');
      bodyElement?.classList.remove('overflow-hidden');
      props.onClose();
    };
  }, []);

  const onClose = () => {
    const element: any = document.getElementById('slider-image-overlay-id');
    element?.classList.add('d-none');
    const bodyElement: any = document.getElementById('tl-body-id');
    bodyElement?.classList.remove('overflow-hidden');
    props.onClose();
  };

  return (
    <>
      <div className="cursor-pointer" style={{ position: 'absolute', top: 20, right: 20, zIndex: '10000' }} onClick={onClose}>
        <i className="ri-close-line" style={{ fontSize: '28px', color: '#fff' }}></i>
      </div>
      <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: '9999', transform: 'translate(-50%, -50%)' }}>
        <Carousel showStatus={false} showThumbs={false}>
          {props.atchFleSeqs.map((item, idx) => (
            <div key={idx}>
              <img src={`${process.env.REACT_APP_API_VIEW_IMAGE}/${item}`} style={{ width: '100%', maxWidth: '80vh', maxHeight: '80vh', objectFit: 'contain' }} />
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}
