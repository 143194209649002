import { forwardRef, useId } from 'react';

type FormFloatingTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  className?: string;
  isError?: boolean;
  errMsg?: string;
};

const FormFloatingTextArea = forwardRef<HTMLTextAreaElement, FormFloatingTextAreaProps>(({ errMsg, isError, label, className, ...restProps }, ref) => {
  const inputId = useId();

  return (
    <div className={'form-floating form-floating-outline ' + (className ? className : '')}>
      <textarea {...restProps} ref={ref} id={inputId} className={'form-control' + (isError ? ' is-invalid' : '')} style={{ height: '120px' }}></textarea>
      <label htmlFor={inputId}>{label}</label>
      {isError && <div className="error-feedback"> {errMsg} </div>}
    </div>
  );
});
export default FormFloatingTextArea;
