import { useNavigate } from 'react-router-dom';

export default function Error404() {
  const navigate = useNavigate();
  return (
    <div className="misc-wrapper">
      <h1 className="mb-2 mx-2" style={{ fontSize: '6rem', lineHeight: '6rem' }}>
        404
      </h1>
      <h4 className="mb-2">Không tìm thấy trang ⚠️</h4>
      <p className="mb-6 mx-2">Chúng tôi không thể tìm thấy trang bạn đang tìm kiếm</p>
      <div className="d-flex justify-content-center mt-9">
        <img src="/assets/img/illustrations/misc-error-object.png" alt="misc-error" className="img-fluid misc-object d-none d-lg-inline-block" width="160" />
        <img src="/assets/img/illustrations/misc-bg-light.png" alt="misc-error" className="misc-bg d-none d-lg-inline-block" />
        <div className="d-flex flex-column align-items-center z-3">
          <img src="/assets/img/404.png" alt="misc-error" className="img-fluid z-1" width="500" />
          <div>
            <a
              onClick={() => {
                navigate('/order-mgt');
              }}
              className="btn btn-primary text-center my-10 waves-effect waves-light text-white"
            >
              Quay lại trang chủ
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
